import * as client from "../../../client";
import { Dialog } from "primereact/dialog";
import {
  useAdditionalFiles,
  useDeleteAdditionalFile,
  useUpdateReport,
  useUploadAdditionalFiles,
} from "../data";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import styles from "./UploadFileModal.module.css";
import { downloadAdditionalFile } from "../../../api/routes";
import { downloadBlob } from "../../../util";
import { useState } from "react";
import { isEqual } from "lodash";

export const UploadFileModal = ({
  report,
  visible,
  onHide,
}: {
  report: client.CommercialReport;
  visible: boolean;
  onHide: () => void;
}) => {
  const [progress, setProgress] = useState("");
  const initialFiles = report.additional_files_used ?? [];
  const additionalFiles = useAdditionalFiles(report.report_id, visible);
  const uploadFile = useUploadAdditionalFiles(report.report_id, (e) => {
    setProgress(
      e.progress
        ? e.progress < 1
          ? `Uploading... ${Math.round(e.progress * 100)}%`
          : "Processing..."
        : ""
    );
  });
  const deleteFile = useDeleteAdditionalFile(report.report_id);
  const updateReport = useUpdateReport(report.report_id);
  const anyPending =
    additionalFiles.isPending ||
    uploadFile.isPending ||
    deleteFile.isPending ||
    updateReport.isPending;

  const dirty = !isEqual(initialFiles, additionalFiles.data);

  return (
    <Dialog
      header="Additional Files"
      visible={visible}
      onHide={onHide}
      closable={false}
    >
      <div className={styles.container}>
        {additionalFiles.isPending ? (
          <ProgressSpinner />
        ) : !(additionalFiles.data && additionalFiles.data.length > 0) ? (
          <div>No additional files added yet.</div>
        ) : (
          <ul>
            {additionalFiles.data.map((file) => (
              <li key={file}>
                <span className={styles.row}>
                  <span>
                    {file}{" "}
                    <b>
                      {initialFiles?.includes(file)
                        ? ""
                        : "(not yet processed)"}
                    </b>
                  </span>
                  <div className={`p-inputgroup ${styles.buttons}`}>
                    <Button
                      disabled={anyPending}
                      onClick={async () => {
                        const response = await downloadAdditionalFile(
                          report.report_id,
                          file
                        );
                        downloadBlob(file, response);
                      }}
                      icon="pi pi-download"
                      size="small"
                      text
                      tooltip="Download"
                      tooltipOptions={{ showDelay: 500, position: "top" }}
                    ></Button>
                    <Button
                      disabled={anyPending}
                      onClick={() => deleteFile.mutate([file])}
                      icon="pi pi-times"
                      size="small"
                      text
                      tooltip="Remove"
                      tooltipOptions={{ showDelay: 500, position: "top" }}
                    ></Button>
                  </div>
                </span>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.footer}>
          <FileUpload
            auto
            mode="basic"
            multiple
            customUpload
            chooseLabel="Add additional files to this report"
            uploadHandler={async (e) => {
              await uploadFile.mutateAsync(e.files);
              e.options.clear();
              setProgress("");
            }}
            disabled={anyPending}
          ></FileUpload>
          {progress}
          <Button
            disabled={anyPending}
            onClick={async () => {
              if (dirty) {
                setProgress("Re-generating report...");
                await updateReport.mutateAsync({ rerunReport: true });
                setProgress("");
              }
              onHide();
            }}
          >
            {!dirty ? "Cancel" : "Re-process report"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
