import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export const DataTableArticle1 = () => {
  const data = [
    {
      layerId: "1",
      layer: "15 x 10",
      aal: "6",
      reinsuranceCost: "7.8",
      reinsuranceMargin: "130%",
    },
    {
      layerId: "2",
      layer: "25 x 25",
      aal: "5",
      reinsuranceCost: "7.5",
      reinsuranceMargin: "150%",
    },
    {
      layerId: "3",
      layer: "50 x 50",
      aal: "4",
      reinsuranceCost: "6.8",
      reinsuranceMargin: "170%",
    },
    {
      layerId: "4",
      layer: "100 x 100",
      aal: "3",
      reinsuranceCost: "5.7",
      reinsuranceMargin: "190%",
    },
    {
      layerId: "5",
      layer: "200 x 200",
      aal: "2",
      reinsuranceCost: "4.2",
      reinsuranceMargin: "210%",
    },
    {
      layerId: "6",
      layer: "400 x 400",
      aal: "1",
      reinsuranceCost: "2.3",
      reinsuranceMargin: "230%",
    },
  ];
  return (
    <DataTable value={data}>
      <Column header={"Layer ID"} field="layerId" style={{ width: "15%" }} />
      <Column header={"Layer Details"} field="layer" style={{ width: "20%" }} />
      <Column header={"AAL"} field="aal" style={{ width: "12%" }} />
      <Column
        header={"Reinsurance Cost"}
        field="reinsuranceCost"
        style={{ width: "25%" }}
      />
      <Column
        header={"Reinsurance Margin"}
        field="reinsuranceMargin"
        style={{ width: "28%" }}
      />
    </DataTable>
  );
};

export const DataTableArticle2 = () => {
  const data = [
    {
      layerId: "1",
      event: "Hurricane Hits FL",
      rate: "0.1",
      portfolioLoss: "$400,000,000",
      flLoss: "$3,000,000",
      vtLoss: "-",
    },
    {
      layerId: "2",
      event: "Boston Winter Storm",
      rate: "0.35",
      portfolioLoss: "$30,000,000",
      flLoss: "-",
      vtLoss: "$857,143",
    },
    {
      layerId: "3",
      event: "Texas SCS",
      rate: "0.25",
      portfolioLoss: "$80,000,000",
      flLoss: "-",
      vtLoss: "-",
    },
    {
      layerId: "4",
      event: "Montana Wildfire",
      rate: "0.2",
      portfolioLoss: "$15,000,000",
      flLoss: "-",
      vtLoss: "-",
    },
  ];
  return (
    <DataTable value={data}>
      <Column header={"ID"} field="layerId" />
      <Column header={"Event"} field="event" />
      <Column header={"Rate"} field="rate" />
      <Column header={"Portfolio Loss"} field="portfolioLoss" />
      <Column header={"FL Marginal Policy Loss"} field="flLoss" />
      <Column header={"VT Marginal Policy Loss"} field="vtLoss" />
    </DataTable>
  );
};
