import styles from "./PrintableReport.module.css";
import * as client from "../../client";
import { LayersCard } from "./layers/LayersCard";
import { Wordmark } from "../../components/wordmark/Wordmark";
import { CompanyInfoHeader } from "./CompanyInfoHeader";
import "./PrintableReportStyles.css";
import { SummaryCard } from "./summary/SummaryCard";
import { Message } from "primereact/message";
import { cloneDeep, countBy } from "lodash";
import { useMemo } from "react";
import { Card } from "primereact/card";
import { SafeReportResponse } from "./Conversion";

type PrintableReportProps = {
  specialtyPropertyInfo: SafeReportResponse;
  rawSpecialtyPropertyInfo: client.ReportResponse;
};

type PrintableReportComponentProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

export const PrintableReport: React.FC<PrintableReportProps> = ({
  specialtyPropertyInfo,
  rawSpecialtyPropertyInfo,
}) => {
  return (
    <div className={styles.printContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>
          {specialtyPropertyInfo.report_json.company_info.company_name}
        </h2>
        <Wordmark height="24px" themeOverride="light" />
      </div>
      <CompanyInfoHeader specialtyPropertyInfo={specialtyPropertyInfo} />
      <span style={{ marginTop: "-24px" }}>
        {specialtyPropertyInfo.report_json.company_info.company_description}
      </span>
      <FakeRenewalLine />
      {/*
      <div className={styles.pageBreakAvoid}>
        <RiskDetailsCardPrintVariant
          specialtyPropertyInfo={specialtyPropertyInfo}
        />
      </div>
      */}
      <div className={styles.pageBreakAvoid}>
        <LayersCard
          specialtyPropertyInfo={specialtyPropertyInfo}
          title="Proposed Layers"
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <FakePreviousLayersCard specialtyPropertyInfo={specialtyPropertyInfo} />
      </div>
      <div className={styles.pageBreakAvoid}>
        <SummaryCard
          rawSpecialtyPropertyInfo={rawSpecialtyPropertyInfo}
          specialtyPropertyInfo={specialtyPropertyInfo}
          forPrint={true}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <NumberOfStoriesHistogram
          specialtyPropertyInfo={specialtyPropertyInfo}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <YearBuiltHistogram specialtyPropertyInfo={specialtyPropertyInfo} />
      </div>
    </div>
  );
};

const FakeRenewalLine = () => {
  return (
    <div>
      <Message text="4 Year Renewal Policy" />{" "}
    </div>
  );
};

function calculateFakeLayerTarget(start: number, end: number): number {
  const baseAmount = 8000000;
  const decayFactor = 0.07;
  const layerSizeFactor = 0.4;

  // Convert to millions for calculation
  const startInMillions = start / 1000000;
  const layerSizeInMillions = (end - start) / 1000000;

  // Calculate components
  const heightComponent = Math.exp(-decayFactor * startInMillions);
  const sizeComponent = Math.pow(layerSizeInMillions / 10, layerSizeFactor);

  // Calculate final target and convert back to full dollars
  const targetAmount = baseAmount * heightComponent * sizeComponent;

  // Round to nearest dollar
  return Math.round(targetAmount);
}

const FakePreviousLayersCard: React.FC<PrintableReportComponentProps> = ({
  specialtyPropertyInfo,
}) => {
  const fakePreviousInfo = useMemo(() => {
    const fakePreviousInfo = cloneDeep(specialtyPropertyInfo);
    fakePreviousInfo.report_json.layer_info =
      fakePreviousInfo.report_json.layer_info.map((info) => {
        const target = calculateFakeLayerTarget(
          info.starting_point,
          info.starting_point + info.range
        );
        return { ...info, target: target };
      });
    return fakePreviousInfo;
  }, [specialtyPropertyInfo]);

  return (
    <LayersCard
      specialtyPropertyInfo={fakePreviousInfo}
      title="Current Layers"
    />
  );
};

const NumberOfStoriesHistogram: React.FC<PrintableReportComponentProps> = ({
  specialtyPropertyInfo,
}) => {
  return (
    <SovHistogram
      specialtyPropertyInfo={specialtyPropertyInfo}
      addressKey="number_of_stories"
      cardTitle="Buildings by Number of Stories"
      getBucket={(stories: number | null) => {
        if (stories === null || isNaN(stories)) {
          return "None";
        }
        if (stories === 1) return "1 Story";
        if (stories === 2) return "2 Stories";
        if (stories >= 3 && stories <= 10) return "3-10 Stories";
        if (stories > 10 && stories <= 20) return "10-20 Stories";
        return "20+ Stories";
      }}
      orderedBuckets={[
        "None",
        "1 Story",
        "2 Stories",
        "3-10 Stories",
        "10-20 Stories",
        "20+ Stories",
      ]}
    />
  );
};

const YearBuiltHistogram: React.FC<PrintableReportComponentProps> = ({
  specialtyPropertyInfo,
}) => {
  return (
    <SovHistogram
      specialtyPropertyInfo={specialtyPropertyInfo}
      addressKey="year_built"
      cardTitle="Buildings by Year Built"
      getBucket={(year: number | null) => {
        if (year === null || isNaN(year)) {
          return "None";
        }
        if (year <= 1929) return "1700-1929";
        if (year <= 1959) return "1930-1959";
        if (year <= 1989) return "1960-1989";
        if (year <= 2009) return "1990-2009";
        return "2010-Current";
      }}
      orderedBuckets={[
        "None",
        "1700-1929",
        "1930-1959",
        "1960-1989",
        "1990-2009",
        "2010-Current",
      ]}
    />
  );
};

type SovHistogramProps = {
  specialtyPropertyInfo: SafeReportResponse;
  addressKey: keyof client.RawAddress;
  cardTitle: string;
  getBucket: (stories: number | null) => string;
  orderedBuckets: string[];
};

const SovHistogram: React.FC<SovHistogramProps> = ({
  specialtyPropertyInfo,
  addressKey,
  cardTitle,
  getBucket,
  orderedBuckets,
}) => {
  const chartData = useMemo(() => {
    // Get all stories numbers
    const allValues = specialtyPropertyInfo.report_json.sovs.flatMap((sov) =>
      sov.addresses.map((address) => {
        const value = address[addressKey]
          ? parseInt(address[addressKey] as string, 10)
          : null;
        return value;
      })
    );

    // Group into buckets
    const bucketCounts = countBy(allValues, getBucket);

    // Create ordered data array

    const data = orderedBuckets.map((bucket) => ({
      bucket,
      count: bucketCounts[bucket] || 0,
    }));

    // Calculate max for scaling
    const maxCount = Math.max(...data.map((d) => d.count));

    return {
      data,
      maxCount,
    };
  }, [specialtyPropertyInfo]);

  const containerStyle: React.CSSProperties = {
    width: "100%",
    height: "384px", // equivalent to h-96
    padding: "16px",
    position: "relative",
  };

  const barsContainerStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    gap: "8px",
  };

  const barGroupStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "16.666667%", // equivalent to w-1/6
  };

  const barWrapperStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const countLabelStyle: React.CSSProperties = {
    position: "absolute",
    top: "-24px",
    width: "100%",
    textAlign: "center",
    fontSize: "14px",
  };

  const bucketLabelStyle: React.CSSProperties = {
    marginTop: "8px",
    fontSize: "14px",
    textAlign: "center",
  };

  const yAxisStyle: React.CSSProperties = {
    position: "absolute",
    left: 0,
    top: "16px",
    bottom: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    pointerEvents: "none",
  };

  const yAxisLabelStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "#6B7280", // text-gray-500
  };

  return (
    <Card title={cardTitle}>
      <div style={containerStyle}>
        <div style={barsContainerStyle}>
          {chartData.data.map(({ bucket, count }) => {
            const heightPercentage = count / chartData.maxCount;
            const height = 324 * heightPercentage;

            const barStyle = {
              width: "100%",
              backgroundColor: "var(--blue-400)",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: `${height}px`,
              minHeight: count > 0 ? "20px" : "0",
            };

            return (
              <div key={bucket} style={barGroupStyle}>
                <div style={barWrapperStyle}>
                  <div style={barStyle}>
                    <div style={countLabelStyle}>{count}</div>
                  </div>
                </div>
                <div style={bucketLabelStyle}>{bucket}</div>
              </div>
            );
          })}
        </div>

        <div style={yAxisStyle}>
          {[100, 75, 50, 25, 0].map((percent) => (
            <div key={percent} style={yAxisLabelStyle}>
              {Math.round((chartData.maxCount * percent) / 100)}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
