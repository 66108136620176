import { Card } from "primereact/card";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import * as client from "../../../client";
import { ExactMoney } from "../Money";
import { Tooltip } from "primereact/tooltip";
import { downloadBlob, isRunningLocally, useRandomId } from "../../../util";
import styles from "./LossRunCard.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import { DeductibleCalculator } from "./DeductibleCalculator";
import { CATCloudIcon } from "./CloudIcon";
import { SafeReportResponse } from "../Conversion";
import { Button } from "primereact/button";
import { downloadLossRun } from "../../../api/routes";
import { InputText } from "primereact/inputtext";
import { useUpdateReport } from "../data";
import { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { SortOrder } from "primereact/api";
import { deductibleAndNetIncurredDoNotSumToGrossLoss } from "./util";
import {
  HIGHLIGHT_WARNING_DARK_MODE,
  HIGHLIGHT_WARNING_LIGHT_MODE,
} from "../dashboard/util";
import { ThemeContext } from "../../../themes/Theme";
import { Checkbox } from "primereact/checkbox";

const showLossRunEditing = isRunningLocally();

// XXX very dangerous
export const TerribleMoney = ({
  dollars,
  fieldName,
  errors,
  highlightWarning,
  isComputedAggregate,
  ifUndefined,
}: {
  dollars?: number;
  fieldName?: string;
  highlightWarning?: string;
  errors?: client.CouldNotParse[];
  isComputedAggregate: boolean;
  ifUndefined?: string;
}) => {
  const id = useRandomId();
  const warningId = useRandomId();
  const showWarning = errors && errors.length > 0;
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  return (
    <>
      {showWarning ? (
        <Tooltip target={"." + id} className={styles.tooltip}>
          {isComputedAggregate ? (
            `The claim data aggregated in this year had ${errors.length} error(s).`
          ) : (
            <>
              Could not extract a {fieldName} value from the following cells:
              <ul>
                {errors.map((e, i) => (
                  <li key={i}>"{e.value}"</li>
                ))}
              </ul>
            </>
          )}
        </Tooltip>
      ) : null}
      {highlightWarning ? (
        <Tooltip target={"." + warningId} className={styles.tooltip}>
          {highlightWarning}
        </Tooltip>
      ) : null}
      <div className={styles.horizontalContainer}>
        <div style={{ display: "flex" }}>
          <div
            className={warningId}
            style={
              highlightWarning
                ? {
                    padding: "2px",
                    backgroundColor: isLightMode
                      ? HIGHLIGHT_WARNING_LIGHT_MODE
                      : HIGHLIGHT_WARNING_DARK_MODE,
                  }
                : {}
            }
          >
            {dollars !== undefined ? (
              <ExactMoney cents={Math.round(dollars * 100)} />
            ) : ifUndefined !== undefined ? (
              ifUndefined
            ) : null}
          </div>
        </div>
        {showWarning ? (
          <div className={id}>
            <span
              className={`pi pi-exclamation-triangle ${styles.warningIcon}`}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

type LossRunCardProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

const download = async (reportId: string, companyName: string) => {
  // Download the zip file as a blob
  const blob = await downloadLossRun(reportId);
  const filename = `${companyName}_Loss_Run_.xlsx`;
  downloadBlob(filename, blob);
};

export const LossRunCard: React.FC<LossRunCardProps> = ({
  specialtyPropertyInfo,
}) => {
  const lossRunInfo = specialtyPropertyInfo.report_json.loss_run;
  const deductibleInfo = specialtyPropertyInfo.report_json.deductible_info;
  return (
    <Card title="Loss Run" style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        <Button
          label="Download"
          severity="secondary"
          raised
          text
          icon="pi pi-file-excel"
          onClick={() =>
            download(
              specialtyPropertyInfo.report_id,
              specialtyPropertyInfo.report_json.company_info.company_name
            )
          }
        />
      </div>
      <TabView style={{ marginTop: "-8px" }}>
        <TabPanel header="By Year">
          <YearByYearLossRunTable
            lossRunInfo={lossRunInfo}
            reportId={specialtyPropertyInfo.report_id}
          />
        </TabPanel>
        <TabPanel header="By Claim">
          <ClaimByClaimLossRunTable
            lossRunInfo={lossRunInfo}
            reportId={specialtyPropertyInfo.report_id}
          />
        </TabPanel>
        <TabPanel header="Deductible Calculator">
          <DeductibleCalculator
            lossRunInfo={lossRunInfo}
            deductibleInfo={deductibleInfo}
          />
        </TabPanel>
      </TabView>
    </Card>
  );
};

type LossRunTableProps = {
  reportId: string;
  lossRunInfo: client.LossRunInfo;
};

const Editor = ({
  options,
  type = "text",
}: {
  options: ColumnEditorOptions;
  type?: "text" | "number";
}) => {
  let textValue = options.value;
  if (typeof textValue === "object") {
    textValue = textValue.value;
  }

  return (
    <InputText
      type={type}
      value={textValue}
      onChange={(e) =>
        options.editorCallback && options.editorCallback(e.target.value)
      }
    />
  );
};

const AddLossYearModal = ({
  lossRunInfo,
  lossRuns,
  reportId,
  visible,
  onClose,
}: {
  lossRunInfo: client.LossRunInfo;
  lossRuns: client.LossRunByYear[];
  reportId: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const updateReport = useUpdateReport(reportId);

  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [openClaims, setOpenClaims] = useState("0");
  const [closedClaims, setClosedClaims] = useState("0");
  const [groundUpLoss, setGroundUpLoss] = useState("0");
  const [netIncurred, setNetIncurred] = useState("0");

  const onSave = () => {
    const newLossRunYear: client.LossRunByYear = {
      policy_year: year,
      description_of_losses: description,
      net_incurred:
        netIncurred !== ""
          ? { value: Number.parseFloat(netIncurred), errors: [] }
          : null,
      ground_up_loss:
        groundUpLoss !== ""
          ? { value: Number.parseFloat(groundUpLoss), errors: [] }
          : null,
      open_claims: Number.parseInt(openClaims),
      closed_claims: Number.parseInt(closedClaims),
    };
    const newParsedRuns = [...lossRuns, newLossRunYear];
    newParsedRuns.sort((a, b) => b.policy_year.localeCompare(a.policy_year));
    const newLossRunInfo: client.LossRunInfo = {
      ...lossRunInfo,
      parsed_loss_runs_by_year: newParsedRuns,
    };

    updateReport.mutate({
      lossRunInfo: newLossRunInfo,
    });

    setYear("");
    setDescription("");
    setOpenClaims("0");
    setClosedClaims("0");
    setGroundUpLoss("0");
    setNetIncurred("0");
    onClose();
  };

  return (
    <Dialog
      header={"Add Loss Year"}
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => onClose()}
    >
      <div className={styles.verticalContainer}>
        <div className={styles.horizontalContainer}>
          <span>Year</span>
          <InputText
            type="text"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Description</span>
          <InputText
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Open Claims</span>
          <InputText
            type="number"
            value={openClaims}
            onChange={(e) => setOpenClaims(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Closed Claims</span>
          <InputText
            type="number"
            value={closedClaims}
            onChange={(e) => setClosedClaims(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Ground Up Loss</span>
          <InputText
            type="number"
            value={groundUpLoss}
            onChange={(e) => setGroundUpLoss(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Net Incurred</span>
          <InputText
            type="number"
            value={netIncurred}
            onChange={(e) => setNetIncurred(e.target.value)}
          />
        </div>
        <div>
          <Button
            label="Save"
            onClick={onSave}
            disabled={!year || !description}
          />
        </div>
      </div>
    </Dialog>
  );
};

const YearByYearLossRunTable: React.FC<LossRunTableProps> = ({
  reportId,
  lossRunInfo,
}) => {
  const updateReport = useUpdateReport(reportId);
  const useComputed = lossRunInfo.computed_loss_runs_by_year.length > 0;
  const lossRuns = useComputed
    ? lossRunInfo.computed_loss_runs_by_year
    : lossRunInfo.parsed_loss_runs_by_year;

  const showNetIncurred =
    lossRuns.length === 0 ||
    lossRuns.some((run) => run.net_incurred?.value !== undefined);

  const canEditLossYears =
    showLossRunEditing && (lossRunInfo.loss_runs_by_claim ?? []).length === 0;

  const [addYearModalOpen, setAddYearModalOpen] = useState(false);

  const onEditComplete = (e: DataTableRowEditCompleteEvent) => {
    const floatWithParseErrorsFromPotentialString = (
      value: string | client.FloatWithParseErrors | null
    ): client.FloatWithParseErrors | null => {
      if (typeof value === "string") {
        return { value: Number.parseFloat(value), errors: [] };
      }
      return value;
    };

    const newLossYear: client.LossRunByYear = {
      ...(e.newData as client.LossRunByYear),
      open_claims: Number.parseInt(e.newData["open_claims"]),
      closed_claims: Number.parseInt(e.newData["closed_claims"]),
      ground_up_loss: floatWithParseErrorsFromPotentialString(
        e.newData["ground_up_loss"]
      ),
      net_incurred: floatWithParseErrorsFromPotentialString(
        e.newData["net_incurred"]
      ),
    };

    // Find the matching loss year (by year) and call the update function
    let newYearByYearLosses: client.LossRunByYear[] = lossRuns;
    newYearByYearLosses = newYearByYearLosses.map((loss) =>
      loss.policy_year === newLossYear.policy_year ? newLossYear : loss
    );
    const newLossRunInfo: client.LossRunInfo = {
      ...lossRunInfo,
      parsed_loss_runs_by_year: newYearByYearLosses,
    };

    updateReport.mutate({ lossRunInfo: newLossRunInfo });
  };

  const deleteRow = (year: client.LossRunByYear) => {
    const newLossRunInfo: client.LossRunInfo = {
      ...lossRunInfo,
      parsed_loss_runs_by_year: lossRuns.filter(
        (lossYear) => lossYear.policy_year !== year.policy_year
      ),
    };

    updateReport.mutate({ lossRunInfo: newLossRunInfo });
  };

  return (
    <div className={styles.verticalContainer}>
      {canEditLossYears && (
        <>
          <AddLossYearModal
            lossRunInfo={lossRunInfo}
            lossRuns={lossRuns}
            reportId={reportId}
            visible={addYearModalOpen}
            onClose={() => setAddYearModalOpen(false)}
          />
          <Button
            icon="pi pi-plus"
            text
            onClick={() => setAddYearModalOpen(true)}
          />
        </>
      )}
      <DataTable
        value={lossRuns}
        editMode="row"
        onRowEditComplete={onEditComplete}
        sortField="policy_year"
        sortOrder={SortOrder.DESC}
      >
        {canEditLossYears && (
          <Column
            rowEditor
            bodyStyle={{ textAlign: "center" }}
            headerStyle={{ minWidth: "8rem", width: "8rem" }}
          />
        )}
        {canEditLossYears && (
          <Column
            headerStyle={{ width: "8rem" }}
            body={(data: client.LossRunByYear) => (
              <Button
                icon="pi pi-trash"
                text
                severity="danger"
                onClick={() => deleteRow(data)}
              />
            )}
          />
        )}
        <Column field="policy_year" sortable header="Year" />
        <Column
          field="description_of_losses"
          header="Description"
          style={{ maxWidth: "300px" }}
          editor={(options) => <Editor options={options} />}
        />
        <Column
          header="Total Claims"
          body={(data: client.LossRunByYear) =>
            data.open_claims + data.closed_claims
          }
        />
        <Column
          header="Closed Claims"
          field="closed_claims"
          editor={(options) => <Editor options={options} type="number" />}
        />
        <Column
          header="Open Claims"
          field="open_claims"
          editor={(options) => <Editor options={options} type="number" />}
        />
        <Column
          field="ground_up_loss"
          header="Ground Up Loss"
          body={(data: client.LossRunByYear) => (
            <TerribleMoney
              dollars={data.ground_up_loss?.value}
              fieldName="Ground Up Loss"
              errors={data.ground_up_loss?.errors ?? []}
              isComputedAggregate={useComputed}
              ifUndefined="N/A"
            />
          )}
          editor={(options) => <Editor options={options} type="number" />}
        />
        {showNetIncurred ? (
          <Column
            field="net_incurred"
            header="Net Incurred"
            body={(data: client.LossRunByYear) =>
              data.net_incurred ? (
                <TerribleMoney
                  dollars={data.net_incurred.value}
                  fieldName="Net Incurred"
                  errors={data.net_incurred.errors}
                  isComputedAggregate={useComputed}
                />
              ) : null
            }
            editor={(options) => <Editor options={options} type="number" />}
          />
        ) : null}
      </DataTable>
    </div>
  );
};

const AddLossClaimModal = ({
  lossRunInfo,
  reportId,
  visible,
  onClose,
}: {
  lossRunInfo: client.LossRunInfo;
  reportId: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const updateReport = useUpdateReport(reportId);

  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("closed");
  const [deductible, setDeductible] = useState("");
  const [groundUpLoss, setGroundUpLoss] = useState("0");
  const [netIncurred, setNetIncurred] = useState("0");
  const [isCatEvent, setIsCatEvent] = useState(false);

  const onSave = () => {
    const newLossClaim: client.Loss = {
      policy_year: year,
      description: description,
      date_of_loss: "",
      deductible:
        deductible !== ""
          ? { value: Number.parseFloat(deductible), errors: [] }
          : null,
      net_incurred:
        netIncurred !== ""
          ? { value: Number.parseFloat(netIncurred), errors: [] }
          : null,
      ground_up_loss:
        groundUpLoss !== ""
          ? { value: Number.parseFloat(groundUpLoss), errors: [] }
          : null,
      status: status,
      is_cat_event: isCatEvent,
    };
    const newLossClaims = [
      ...(lossRunInfo.loss_runs_by_claim ?? []),
      newLossClaim,
    ];
    newLossClaims.sort((a, b) => b.policy_year.localeCompare(a.policy_year));
    const newLossRunInfo: client.LossRunInfo = {
      ...lossRunInfo,
      loss_runs_by_claim: newLossClaims,
    };
    updateReport.mutate({
      lossRunInfo: newLossRunInfo,
    });

    setYear("");
    setDescription("");
    setStatus("closed");
    setDeductible("");
    setGroundUpLoss("0");
    setNetIncurred("0");
    setIsCatEvent(false);
    onClose();
  };

  return (
    <Dialog
      header={"Add Loss Year"}
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => onClose()}
    >
      <div className={styles.verticalContainer}>
        <div className={styles.horizontalContainer}>
          <span>Year</span>
          <InputText
            type="text"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Description</span>
          <InputText
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Status</span>
          <InputText
            type="text"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Ground Up Loss</span>
          <InputText
            type="number"
            value={groundUpLoss}
            onChange={(e) => setGroundUpLoss(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Net Incurred</span>
          <InputText
            type="number"
            value={netIncurred}
            onChange={(e) => setNetIncurred(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Deductible</span>
          <InputText
            type="number"
            value={deductible}
            onChange={(e) => setDeductible(e.target.value)}
          />
        </div>
        <div className={styles.horizontalContainer}>
          <span>Is Cat Event</span>
          <Checkbox
            checked={isCatEvent}
            onChange={(e) => setIsCatEvent(e.checked ?? false)}
          />
        </div>
        <div>
          <Button
            label="Save"
            onClick={onSave}
            disabled={!year || !description}
          />
        </div>
      </div>
    </Dialog>
  );
};

const ClaimByClaimLossRunTable: React.FC<LossRunTableProps> = ({
  lossRunInfo,
  reportId,
}) => {
  const updateReport = useUpdateReport(reportId);
  const canEditLossClaims = showLossRunEditing;
  const [addClaimModalOpen, setAddClaimModalOpen] = useState(false);
  const deleteLoss = (loss: client.Loss) => {
    const newLosses = (lossRunInfo.loss_runs_by_claim ?? []).filter(
      (otherLoss) =>
        otherLoss.description !== loss.description ||
        otherLoss.policy_year !== loss.policy_year ||
        otherLoss.deductible?.value !== loss.deductible?.value ||
        otherLoss.ground_up_loss?.value !== loss.ground_up_loss?.value ||
        otherLoss.net_incurred?.value !== loss.net_incurred?.value
    );
    const newLossRunInfo: client.LossRunInfo = {
      ...lossRunInfo,
      loss_runs_by_claim: newLosses,
    };
    updateReport.mutate({ lossRunInfo: newLossRunInfo });
  };

  return (
    <div className={styles.verticalContainer}>
      {canEditLossClaims && (
        <>
          <AddLossClaimModal
            lossRunInfo={lossRunInfo}
            reportId={reportId}
            visible={addClaimModalOpen}
            onClose={() => setAddClaimModalOpen(false)}
          />
          <Button
            icon="pi pi-plus"
            text
            onClick={() => setAddClaimModalOpen(true)}
          />
        </>
      )}
      {lossRunInfo.loss_runs_by_claim === null ||
      lossRunInfo.loss_runs_by_claim.length === 0 ? (
        <span>No individual claims found</span>
      ) : (
        <DataTable
          value={lossRunInfo.loss_runs_by_claim}
          sortField="policy_year"
          sortOrder={SortOrder.DESC}
        >
          {canEditLossClaims && (
            <Column
              headerStyle={{ width: "8rem" }}
              body={(data: client.Loss) => (
                <Button
                  icon="pi pi-trash"
                  text
                  severity="danger"
                  onClick={() => deleteLoss(data)}
                />
              )}
            />
          )}
          <Column field="policy_year" sortable header="Year" />
          <Column
            field="description"
            header="Description"
            style={{ maxWidth: "300px" }}
          />
          <Column
            field="ground_up_loss"
            header="Ground Up Loss"
            body={(data: client.Loss) => (
              <TerribleMoney
                dollars={data.ground_up_loss?.value}
                fieldName="Ground Up Loss"
                errors={data.ground_up_loss?.errors ?? []}
                isComputedAggregate={false}
                ifUndefined="N/A"
              />
            )}
          />
          <Column
            field="deductible"
            header="Deductible"
            body={(data: client.Loss) =>
              data.deductible ? (
                <TerribleMoney
                  dollars={data.deductible.value}
                  fieldName="Deductible"
                  errors={data.deductible.errors}
                  isComputedAggregate={false}
                />
              ) : null
            }
          />
          <Column
            field="net_incurred"
            header="Net Incurred"
            body={(data: client.Loss) => {
              const calculationError =
                deductibleAndNetIncurredDoNotSumToGrossLoss(data);
              return data.net_incurred ? (
                <TerribleMoney
                  dollars={data.net_incurred.value}
                  fieldName="Net Incurred"
                  errors={data.net_incurred.errors}
                  isComputedAggregate={false}
                  highlightWarning={
                    calculationError
                      ? "Ground Up Loss - Deductible does not equal Net Incurred"
                      : undefined
                  }
                />
              ) : null;
            }}
          />
          <Column
            field="is_cat_event"
            header="Is CAT Event"
            body={(data: client.Loss) =>
              data.is_cat_event ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <span>Yes</span>
                  <CATCloudIcon />
                </div>
              ) : (
                <span>No</span>
              )
            }
          />
        </DataTable>
      )}
    </div>
  );
};
