import * as client from "../../../client";
import { SafeReportResponse } from "../Conversion";
import { formatAbbreviatedMoney } from "../Money";

export type InsuranceLayer = {
  startingPoint: number;
  range: number;
  rated: number | null;
  target: number | null;
};

export const sortedLayersFromReport = (
  specialtyPropertyInfo: SafeReportResponse
) => {
  const layers = specialtyPropertyInfo.report_json.layer_info.map((layer) => ({
    startingPoint: layer.starting_point,
    range: layer.range,
    target: layer.target,
    rated: layer.rated,
  }));

  return layers.sort((a, b) => {
    if (a.startingPoint === b.startingPoint) {
      return b.range - a.range;
    }

    return b.startingPoint - a.startingPoint;
  });
};

export const readableLayer = (layer: InsuranceLayer) => {
  const { startingPoint, range } = layer;
  return startingPoint === 0
    ? `Primary ${formatAbbreviatedMoney(range)}`
    : `${formatAbbreviatedMoney(range)} xs ${formatAbbreviatedMoney(
        startingPoint
      )}`;
};

export function hasOverlappingLayers(objects: InsuranceLayer[]): boolean {
  const overlappingPairs: [InsuranceLayer, InsuranceLayer][] = [];

  // Compare each object with every other object
  for (let i = 0; i < objects.length; i++) {
    for (let j = i + 1; j < objects.length; j++) {
      const obj1 = objects[i];
      const obj2 = objects[j];

      // Calculate the end points for each range
      const obj1End = obj1.startingPoint + obj1.range;
      const obj2End = obj2.startingPoint + obj2.range;

      // Check for overlap
      // Two ranges overlap if the start of one range is less than the end of the other range
      // AND the end of the first range is greater than the start of the other range
      if (obj1.startingPoint < obj2End && obj1End > obj2.startingPoint) {
        overlappingPairs.push([obj1, obj2]);
      }
    }
  }

  return overlappingPairs.length > 0;
}

export const lossRunErrorsForReport = (
  specialtyPropertyInfo: SafeReportResponse
) => {
  const errors: client.CouldNotParse[] = [];
  const lossRunsByClaim =
    specialtyPropertyInfo.report_json.loss_run.loss_runs_by_claim;
  const lossRunsByYear =
    specialtyPropertyInfo.report_json.loss_run.parsed_loss_runs_by_year;

  if (lossRunsByClaim && lossRunsByClaim.length > 0) {
    errors.push(
      ...lossRunsByClaim
        .map((claim) => claim.ground_up_loss?.errors ?? [])
        .flatMap((e) => e)
    );
    errors.push(
      ...lossRunsByClaim
        .map((claim) => claim.net_incurred?.errors ?? [])
        .flatMap((e) => e)
    );
    errors.push(
      ...lossRunsByClaim
        .map((claim) => claim.deductible?.errors ?? [])
        .flatMap((e) => e)
    );
  } else if (lossRunsByYear) {
    errors.push(
      ...lossRunsByYear
        .map((claim) => claim.ground_up_loss?.errors ?? [])
        .flatMap((e) => e)
    );
    errors.push(
      ...lossRunsByYear
        .map((claim) => claim.net_incurred?.errors ?? [])
        .flatMap((e) => e)
    );
  }

  return errors;
};
