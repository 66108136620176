import * as client from "../../../client";

type FlagIds = client.Address["private_flags"][string][number];

export const LOW_CONFIDENCE_MINIMUM_SEVERITY = 80;

export const severityForFlag = (flagId: FlagIds): number => {
  switch (flagId) {
    case "date_parsing_error":
      return 85;
    case "missing_coverage_headers":
      return 90;
    case "number_parsing_error":
      return 70;
    case "suspicious_year_warning":
      return 81;
    case "general_mismatch":
      return 70;
    case "large_number_warning":
      return 90;
    case "low_confidence":
      return 81;
    case "medium_confidence":
      return 65;
    case "year_out_of_range_warning":
      return 64;
  }
};

export const maxSeverityForAddressFlags = (
  address: client.Address,
  addressField: keyof client.RawAddress | undefined
): number => {
  if (addressField === undefined) {
    return 0;
  }
  let maxSeverity = undefined;
  for (const flagId of address.private_flags[addressField] ?? []) {
    const newSeverity = severityForFlag(flagId);

    if (newSeverity > (maxSeverity ?? 0)) {
      maxSeverity = newSeverity;
    }
  }
  return maxSeverity ?? 0;
};

const flagText = (flag: FlagIds, originalValue: string): string => {
  switch (flag) {
    case "date_parsing_error":
      return `Failed to parse date: "${originalValue}"`;
    case "missing_coverage_headers":
      return "No coverage headers found that sum to the TIV. Defaulting all TIV to Building Value.";
    case "number_parsing_error":
      return `Failed to parse number value: "${originalValue}"`;
    case "suspicious_year_warning":
      return `Warning when extracting a 4 digit year from: "${originalValue}"`;
    case "general_mismatch":
      return `The extracted value looks different than the original: "${originalValue}"`;
    case "large_number_warning":
      return "This number looks too large to be correct.";
    case "low_confidence":
      return "We have low confidence in this value.";
    case "medium_confidence":
      return "We have medium confidence in this value.";
    case "year_out_of_range_warning":
      return `This year was adjusted up to 1905 to avoid issues in excel (originally, this value was "${originalValue}")`;
  }
};

export const flagTextFor = (
  address: client.Address,
  addressField: keyof client.RawAddress | undefined
) => {
  if (addressField === undefined) {
    return undefined;
  }

  if (address.private_overrides[addressField]) {
    return "A user edited this value";
  }

  const originalValue =
    address.private_original_values[addressField] ?? "NONE GIVEN";

  return (address.private_flags[addressField] ?? [])
    .map((flag) => flagText(flag, originalValue))
    .map((text) => `- ${text}`)
    .join("\n");
};
