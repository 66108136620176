import styles from "./SpecialtyReport.module.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMemo, useState, useEffect } from "react";
import * as client from "../../client";

// Component imports
import { Conversation } from "./Conversation";
import { LayersCard } from "./layers/LayersCard";
import { LossRunCard } from "./loss-run/LossRunCard";
import { AddressCard } from "./sov/AddressCard";
import { ReportActionMenu } from "./dashboard/ReportActionMenu";
import { StatusIcon } from "./dashboard/StatusIcon";
import { PantheonAvatar } from "../../components/avatar/Avatar";
import { PrintableReport } from "./PrintableReport";
import { RiskDetailsCard } from "./RiskDetailsCard";
import { CompanyInfoHeader } from "./CompanyInfoHeader";
import { AppetiteModal } from "./appetite/AppetiteModal";
import { UploadFileModal } from "./upload_file/UploadFileModal";
import { AppetiteIcon } from "./appetite/AppetiteIcon";
import { FinancialCard } from "./financial/FinancialCard";
import { CrimeCard } from "./crime/CrimeCard";
import { PolicyCard } from "./policy/PolicyCard";
import { NewsCard } from "./news/NewsCard";
import { SummaryCard } from "./summary/SummaryCard";

// Utilities
import { useReport, useUserById } from "./data";
import {
  appetiteScoreForReportInfo,
  TEMP_PIPELINE_EXAMPLES,
} from "./dashboard/util";
import { displayNameForUser, sleepMs } from "../../util";
import { ROUTES } from "../../routes";
import { ReferralCard } from "./referral/ReferralCard";
import { applyOverridesToResponse, SafeReportResponse } from "./Conversion";
import { LossControlReports } from "./LossControlReports";

interface RoutedTabViewProps {
  rawSpecialtyPropertyInfo: client.ReportResponse;
  safeSpecialtyPropertyInfo: SafeReportResponse;
  isCasualty: boolean;
  setIsFireMapVisible: (visible: boolean) => void;
}

// Type for tab configuration
interface TabConfig {
  header: string;
  path: string;
  casualtyOnly?: boolean;
  showIf?: (info: SafeReportResponse) => boolean;
  component:
    | { type: "safe"; component: React.ComponentType<SafeTabComponentProps> }
    | { type: "raw"; component: React.ComponentType<RawTabComponentProps> }
    | {
        type: "safe_and_raw";
        component: React.ComponentType<SafeAndRawComponentProps>;
      };
}

// Common props that might be passed to tab components
interface CommonTabComponentProps {
  forPrint?: boolean;
  isCasualty?: boolean;
  setMapVisible?: (visible: boolean) => void;
}

type RawTabComponentProps = CommonTabComponentProps & {
  specialtyPropertyInfo: client.ReportResponse;
};

type SafeTabComponentProps = CommonTabComponentProps & {
  specialtyPropertyInfo: SafeReportResponse;
};

type SafeAndRawComponentProps = CommonTabComponentProps & {
  specialtyPropertyInfo: SafeReportResponse;
  rawSpecialtyPropertyInfo: client.ReportResponse;
};

// Define all possible tabs with their conditions
const ALL_TABS: TabConfig[] = [
  {
    header: "Summary",
    path: "summary",
    component: { type: "safe_and_raw", component: SummaryCard },
  },
  {
    header: "Underwriting",
    path: "underwriting",
    component: { type: "safe", component: ReferralCard },
  },
  {
    header: "Risk Details",
    path: "risk-details",
    component: { type: "safe", component: RiskDetailsCard },
  },
  {
    header: "Finances",
    path: "finances",
    casualtyOnly: true,
    component: { type: "safe", component: FinancialCard },
  },
  {
    header: "Crime",
    path: "crime",
    casualtyOnly: true,
    component: { type: "safe", component: CrimeCard },
  },
  {
    header: "Layers",
    path: "layers",
    component: { type: "safe", component: LayersCard },
  },
  {
    header: "Loss Run",
    path: "loss-run",
    component: { type: "safe", component: LossRunCard },
  },
  {
    header: "Loss Control Reports",
    path: "loss-control-reports",
    component: { type: "safe", component: LossControlReports },
  },
  {
    header: "SOV",
    path: "sov",
    component: { type: "raw", component: AddressCard },
  },
  {
    header: "Policy Information",
    path: "policy",
    showIf: (info) => !!info.report_json.deductible_info,
    component: { type: "safe", component: PolicyCard },
  },
  {
    header: "Company News",
    path: "news",
    component: { type: "safe", component: NewsCard },
  },
];

const RoutedTabView: React.FC<RoutedTabViewProps> = ({
  safeSpecialtyPropertyInfo,
  rawSpecialtyPropertyInfo,
  isCasualty,
  setIsFireMapVisible,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { tabPath } = useParams<{ tabPath?: string }>();

  // Filter tabs based on conditions
  const availableTabs = ALL_TABS.filter((tab) => {
    if (tab.casualtyOnly && !isCasualty) return false;
    if (tab.showIf && !tab.showIf(safeSpecialtyPropertyInfo)) return false;
    return true;
  });

  // Get the current tab from the URL
  const getCurrentTabIndex = () => {
    const index = availableTabs.findIndex((tab) => tab.path === tabPath);
    return index >= 0 ? index : 0;
  };

  // Handle tab changes
  const handleTabChange = (e: { index: number }) => {
    const newPath = availableTabs[e.index].path;
    navigate(
      ROUTES.DASHBOARD_REPORT(safeSpecialtyPropertyInfo.report_id, newPath)
    );
  };

  // Set initial tab on mount
  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    if (!currentPath || currentPath === safeSpecialtyPropertyInfo.report_id) {
      navigate(
        ROUTES.DASHBOARD_REPORT(
          safeSpecialtyPropertyInfo.report_id,
          availableTabs[0].path
        )
      );
    }
  }, [location.pathname, safeSpecialtyPropertyInfo.report_id]);

  return (
    <TabView
      className={styles.tabView}
      activeIndex={getCurrentTabIndex()}
      onTabChange={handleTabChange}
    >
      {availableTabs.map((tab) => {
        const setMapVisible =
          tab.path === "sov" && safeSpecialtyPropertyInfo.report_json.html_map
            ? setIsFireMapVisible
            : undefined;
        return (
          <TabPanel key={tab.path} header={tab.header}>
            {tab.component.type === "safe" ? (
              <tab.component.component
                specialtyPropertyInfo={safeSpecialtyPropertyInfo}
                forPrint={false}
                isCasualty={tab.path === "sov" ? isCasualty : undefined}
                setMapVisible={setMapVisible}
              />
            ) : (
              <>
                {tab.component.type === "safe_and_raw" ? (
                  <tab.component.component
                    rawSpecialtyPropertyInfo={rawSpecialtyPropertyInfo}
                    specialtyPropertyInfo={safeSpecialtyPropertyInfo}
                    forPrint={false}
                    isCasualty={tab.path === "sov" ? isCasualty : undefined}
                    setMapVisible={setMapVisible}
                  />
                ) : (
                  <tab.component.component
                    specialtyPropertyInfo={rawSpecialtyPropertyInfo}
                    forPrint={false}
                    isCasualty={tab.path === "sov" ? isCasualty : undefined}
                    setMapVisible={setMapVisible}
                  />
                )}
              </>
            )}
          </TabPanel>
        );
      })}
    </TabView>
  );
};

export const SpecialtyReport: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isFireMapVisible, setIsFireMapVisible] = useState(false);
  const [appetiteModalVisible, setAppetiteModalVisible] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const reportResponse = useReport(id);
  const specialtyPropertyInfo = useMemo(
    () => (reportResponse.isSuccess ? reportResponse.data : undefined),
    [reportResponse.isSuccess, reportResponse.data]
  );

  const safeResponse = useMemo(
    () =>
      specialtyPropertyInfo
        ? applyOverridesToResponse(specialtyPropertyInfo)
        : undefined,
    [specialtyPropertyInfo]
  );

  // TODO: use a flag on the report
  const isCasualty =
    specialtyPropertyInfo?.report_json.company_info.company_name ===
    "Lake Forest II";

  const assignee = useUserById(specialtyPropertyInfo?.assignee);

  useEffect(() => {
    (async () => {
      if (isPrinting) {
        await sleepMs(0);
        window.print();
        await sleepMs(0);
        setIsPrinting(false);
      }
    })();
  }, [isPrinting]);

  if (id === undefined) {
    // This should never happen
    return <></>;
  }

  // TODO: return some sort of failure state if we couldn't find the report
  if (specialtyPropertyInfo === undefined || safeResponse === undefined) {
    return <></>;
  }

  return (
    <>
      {isPrinting ? (
        <PrintableReport
          specialtyPropertyInfo={safeResponse}
          rawSpecialtyPropertyInfo={specialtyPropertyInfo}
        />
      ) : null}
      <AppetiteModal
        report={specialtyPropertyInfo.report_json}
        visible={appetiteModalVisible}
        onHide={() => setAppetiteModalVisible(false)}
      />
      <UploadFileModal
        report={specialtyPropertyInfo.report_json}
        visible={uploadModalOpen}
        onHide={() => setUploadModalOpen(false)}
      />
      <div className={styles.container}>
        <div className={styles.verticalContainer}>
          <Card
            title={specialtyPropertyInfo.report_json.company_info.company_name}
            subTitle={
              <div className={styles.statusContainer}>
                <div className={styles.roundedHeaderPiece}>
                  <div className={styles.assigneeContainer}>
                    <PantheonAvatar label={displayNameForUser(assignee)} />
                    <span className={styles.assigneeName}>
                      {displayNameForUser(assignee) ?? "Unassigned"}
                    </span>
                  </div>
                </div>
                <div className={styles.roundedHeaderPiece}>
                  <StatusIcon status={specialtyPropertyInfo.status} />
                </div>
                <div className={styles.roundedHeaderPiece}>
                  <AppetiteIcon
                    onClick={() => setAppetiteModalVisible(true)}
                    size="full_width"
                    appetite={appetiteScoreForReportInfo(
                      specialtyPropertyInfo.report_json.business_classification,
                      specialtyPropertyInfo.report_json.appetite_score
                    )}
                  />
                </div>
              </div>
            }
            style={{ position: "relative" }}
          >
            <CompanyInfoHeader specialtyPropertyInfo={safeResponse} />
            <span>
              {
                specialtyPropertyInfo.report_json.company_info
                  .company_description
              }
            </span>
            <div className={styles.topRightButtons}>
              <Button
                className={styles.textButton}
                onClick={() => setUploadModalOpen(true)}
                severity="secondary"
                text
                icon="pi pi-cloud-upload"
                tooltip="Upload additional files"
                tooltipOptions={{ position: "bottom" }}
              />
              <Button
                className={styles.textButton}
                onClick={() => setIsPrinting(true)}
                severity="secondary"
                text
                icon="pi pi-download"
                tooltip="Print report"
                tooltipOptions={{ position: "bottom" }}
              />
              <ReportActionMenu
                report={{
                  report_metadata: { ...specialtyPropertyInfo.report_json },
                  ...specialtyPropertyInfo,
                }}
                isUpcomingRenewal={TEMP_PIPELINE_EXAMPLES.has(
                  specialtyPropertyInfo.report_json.company_info.company_name
                )}
              />
            </div>
          </Card>
          <RoutedTabView
            rawSpecialtyPropertyInfo={specialtyPropertyInfo}
            safeSpecialtyPropertyInfo={safeResponse}
            isCasualty={isCasualty}
            setIsFireMapVisible={setIsFireMapVisible}
          />
          <Dialog
            header={`${specialtyPropertyInfo.report_json.company_info.company_name} Addresses`}
            visible={isFireMapVisible}
            style={{
              width: "calc(100vw - 72px)",
              height: "calc(100vh - 72px)",
            }}
            onHide={() => {
              if (!isFireMapVisible) return;
              setIsFireMapVisible(false);
            }}
          >
            <iframe
              srcDoc={specialtyPropertyInfo.report_json.html_map ?? ""}
              title="Fire Clusters"
              className={styles.iframe}
            />
          </Dialog>
        </div>
        <Conversation specialtyPropertyInfo={specialtyPropertyInfo} />
      </div>
    </>
  );
};
