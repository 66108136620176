import { Card } from "primereact/card";
import * as client from "../../../client";
import styles from "./Analytics.module.css";
import { useContext, useState } from "react";
import { Chart } from "primereact/chart";
import { ThemeContext } from "../../../themes/Theme";
import { appetiteScoreForReportInfo } from "./util";
import { appetiteTitleForScore } from "../appetite/AppetiteIcon";
import { Dropdown } from "primereact/dropdown";
import { useUsers } from "../data";
import { MultiSelect } from "primereact/multiselect";
import { displayNameForUser } from "../../../util";
import { Avatar } from "primereact/avatar";
import { generateIdenticonDataUrl } from "../../../components/avatar/Avatar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

type DashboardAnalyticsProps = {
  specialtyReports: client.ReportMetadataResponse[];
};

type Year = {
  name: string;
  code: string;
};

export const DashboardAnalytics: React.FC<DashboardAnalyticsProps> = ({
  specialtyReports,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const gridLineColor = isLightMode
    ? "rgba(0, 0, 0, 0.1)"
    : "rgba(255, 255, 255, 0.2)";
  const textColor = isLightMode ? "#000000" : "#ffffff";

  const users = (useUsers()?.data?.all_company_users ?? []).map((user) => {
    return { ...user, full_name: user.full_name ?? user.email };
  });
  const [selectedUsers, setSelectedUsers] = useState<client.User[]>([]);

  const years: Year[] = [
    { name: "2025", code: "2025" },
    { name: "2024", code: "2024" },
    { name: "2023", code: "2023" },
  ];
  const [selectedYear, setSelectedYear] = useState<Year>({
    name: "2024",
    code: "2024",
  });

  return (
    <div className={styles.container}>
      <Filters
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        years={years}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
      <div className={styles.gridContainer}>
        <Card title="Gross Written Premium" className={styles.analyticsCard}>
          <WrittenPremiumChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
            selectedUsers={selectedUsers}
            selectedYear={selectedYear}
            allUsers={users}
          />
        </Card>
        <Card title="Submissions" className={styles.analyticsCard}>
          <OverviewChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
            selectedUsers={selectedUsers}
            selectedYear={selectedYear}
            allUsers={users}
          />
        </Card>
        <Card
          title="Monthly Submissions By Brokerage"
          className={styles.analyticsCard}
        >
          <BrokerageChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
            selectedUsers={selectedUsers}
            selectedYear={selectedYear}
            allUsers={users}
          />
        </Card>
        <Card title="Submissions By Appetite" className={styles.analyticsCard}>
          <AppetiteChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
            selectedUsers={selectedUsers}
            selectedYear={selectedYear}
            allUsers={users}
          />
        </Card>
        <Card
          title="Business Type By Broker"
          className={styles.analyticsCard}
          style={{ width: "1210px" }}
        >
          <BusinessTypeByBrokerChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
            selectedUsers={selectedUsers}
            selectedYear={selectedYear}
            allUsers={users}
          />
        </Card>
      </div>
    </div>
  );
};

type FiltersProps = {
  users: client.User[];
  selectedUsers: client.User[];
  setSelectedUsers: (users: client.User[]) => void;
  years: Year[];
  selectedYear: Year;
  setSelectedYear: (year: Year) => void;
};

const Filters: React.FC<FiltersProps> = ({
  users,
  selectedUsers,
  setSelectedUsers,
  years,
  selectedYear,
  setSelectedYear,
}) => {
  return (
    <div className={styles.horizontalContainer}>
      <MultiSelect
        value={selectedUsers}
        onChange={(e) => setSelectedUsers(e.value)}
        placeholder="All Underwriters"
        optionLabel="full_name"
        options={users}
        style={{ maxWidth: "200px" }}
        itemTemplate={(user: client.User) => {
          const name = displayNameForUser(user);
          return (
            <div className={styles.horizontalContainer}>
              <Avatar
                label={name.toUpperCase()}
                shape="circle"
                image={generateIdenticonDataUrl(name)}
                style={{
                  height: "24px",
                  width: "24px",
                }}
              />
              <span>{name}</span>
            </div>
          );
        }}
      />
      <Dropdown
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.value)}
        options={years}
        optionLabel="name"
        placeholder="Select a Year"
      />
    </div>
  );
};

const GREEN = {
  backgroundColor: "rgba(75, 192, 192, 0.2)",
  borderColor: "rgb(75, 192, 192)",
};
const BLUE = {
  backgroundColor: "rgba(54, 162, 235, 0.2)",
  borderColor: "rgb(54, 162, 235)",
};
const ORANGE = {
  backgroundColor: "rgba(255, 159, 64, 0.2)",
  borderColor: "rgb(255, 159, 64)",
};
const RED = {
  backgroundColor: "rgba(255, 99, 132, 0.2)",
  borderColor: "rgb(255, 99, 132)",
};
const YELLOW = {
  backgroundColor: "rgba(255, 206, 86, 0.2)",
  borderColor: "rgb(255, 206, 86)",
};

const colorPalette = [
  ORANGE,
  GREEN,
  BLUE,
  {
    backgroundColor: "rgba(153, 102, 255, 0.2)",
    borderColor: "rgb(153, 102, 255)",
  },
  RED,
  YELLOW,
  {
    backgroundColor: "rgba(201, 203, 207, 0.2)",
    borderColor: "rgb(201, 203, 207)",
  },
  {
    backgroundColor: "rgba(153, 255, 153, 0.2)",
    borderColor: "rgb(153, 255, 153)",
  },
  {
    backgroundColor: "rgba(102, 51, 153, 0.2)",
    borderColor: "rgb(102, 51, 153)",
  },
];

type ChartProps = {
  specialtyReports: client.ReportMetadataResponse[];
  gridLineColor: string;
  textColor: string;
  selectedYear: Year;
  allUsers: client.User[];
  selectedUsers: client.User[];
};

function modifyTestDataForUsers(
  data: number,
  selectedUsers: client.User[],
  allUsers: client.User[]
): number;
function modifyTestDataForUsers(
  data: number[],
  selectedUsers: client.User[],
  allUsers: client.User[]
): number[];
function modifyTestDataForUsers(
  data: number | number[],
  selectedUsers: client.User[],
  allUsers: client.User[]
): number | number[] {
  // Assume no users and all users has no modification
  if (
    selectedUsers.length === 0 ||
    selectedUsers.length === allUsers.length ||
    allUsers.length === 0
  ) {
    return data;
  }

  const percUsers = selectedUsers.length / allUsers.length;

  if (Array.isArray(data)) {
    return data.map((value) => Math.round(value * percUsers));
  }
  return Math.round(data * percUsers);
}

const filterBySelectedUsers = (
  specialtyReports: client.ReportMetadataResponse[],
  selectedUsers: client.User[]
) => {
  return specialtyReports.filter(
    (report) =>
      selectedUsers.length === 0 ||
      (selectedUsers.length > 0 &&
        selectedUsers.some((user) => user.id === report.assignee))
  );
};

const WrittenPremiumChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
  selectedYear,
  selectedUsers,
  allUsers,
}) => {
  const dataByYear: { [key: string]: number[] } = {
    "2023": [130, 140, 18],
    "2024": [140, 120, 16],
    "2025": [150, 160, 20],
  };
  const data = modifyTestDataForUsers(
    dataByYear[selectedYear.code],
    selectedUsers,
    allUsers
  );

  const chartData = {
    labels: ["Target", "Projected", "YTD"],
    datasets: [
      {
        label: "Premium",
        data: data,
        backgroundColor: [
          colorPalette[0].backgroundColor,
          colorPalette[1].backgroundColor,
          colorPalette[2].backgroundColor,
        ],
        borderColor: [
          colorPalette[0].borderColor,
          colorPalette[1].borderColor,
          colorPalette[2].borderColor,
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
          callback: (value: number) => `${value} MM`,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};

const OverviewChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
  specialtyReports,
  selectedUsers,
  selectedYear,
  allUsers,
}) => {
  const dataByYear: { [key: string]: number[] } = {
    2025: [
      filterBySelectedUsers(specialtyReports, selectedUsers).length,
      specialtyReports.filter(
        (report) =>
          (report.status === "uninterested" || report.status === "bound") &&
          (selectedUsers.length === 0 ||
            (selectedUsers.length > 0 &&
              selectedUsers.some((user) => user.id === report.assignee)))
      ).length,
      specialtyReports.filter(
        (report) =>
          report.status === "bound" &&
          (selectedUsers.length === 0 ||
            (selectedUsers.length > 0 &&
              selectedUsers.some((user) => user.id === report.assignee)))
      ).length,
    ],
    2024: modifyTestDataForUsers([2700, 1800, 800], selectedUsers, allUsers),
    2023: modifyTestDataForUsers([2400, 1500, 500], selectedUsers, allUsers),
  };
  const data = dataByYear[selectedYear.code];

  const chartData = {
    labels: ["Total", "Quoted", "Bound"],
    datasets: [
      {
        label: "Submissions",
        data: data,
        backgroundColor: [
          colorPalette[0].backgroundColor,
          colorPalette[1].backgroundColor,
          colorPalette[2].backgroundColor,
          colorPalette[3].backgroundColor,
        ],
        borderColor: [
          colorPalette[0].borderColor,
          colorPalette[1].borderColor,
          colorPalette[2].borderColor,
          colorPalette[3].borderColor,
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};

const AppetiteChart: React.FC<ChartProps> = ({
  textColor,
  gridLineColor,
  specialtyReports,
  selectedYear,
  selectedUsers,
  allUsers,
}) => {
  const codeMappingCurrentYear: { [key: string]: number } = {};
  const filteredReports = filterBySelectedUsers(
    specialtyReports,
    selectedUsers
  );
  for (const report of filteredReports) {
    const appetiteScore = String(
      appetiteScoreForReportInfo(
        report.report_metadata.business_classification,
        report.report_metadata.appetite_score
      )
    );

    if (!(appetiteScore in codeMappingCurrentYear)) {
      codeMappingCurrentYear[appetiteScore] = 1;
    } else {
      codeMappingCurrentYear[appetiteScore] =
        codeMappingCurrentYear[appetiteScore] + 1;
    }
  }

  const codeMappingsByYear: { [key: string]: { [key: string]: number } } = {
    "2025": codeMappingCurrentYear,
    "2024": {
      "5": modifyTestDataForUsers(100, selectedUsers, allUsers),
      "4": modifyTestDataForUsers(160, selectedUsers, allUsers),
      "3": modifyTestDataForUsers(140, selectedUsers, allUsers),
      "2": modifyTestDataForUsers(60, selectedUsers, allUsers),
      "1": modifyTestDataForUsers(130, selectedUsers, allUsers),
    },
    "2023": {
      "5": modifyTestDataForUsers(90, selectedUsers, allUsers),
      "4": modifyTestDataForUsers(150, selectedUsers, allUsers),
      "3": modifyTestDataForUsers(160, selectedUsers, allUsers),
      "2": modifyTestDataForUsers(140, selectedUsers, allUsers),
      "1": modifyTestDataForUsers(80, selectedUsers, allUsers),
    },
  };

  const codeMapping = codeMappingsByYear[selectedYear.code];
  const sortedScores = Object.keys(codeMapping).sort().reverse();
  const appetiteTitles = sortedScores.map((score) =>
    appetiteTitleForScore(Number(score))
  );
  const sortedValues = sortedScores.map((score) => codeMapping[score]);
  const appetiteColors = sortedScores.map((score) => {
    switch (score) {
      case "5":
        return GREEN;
      case "4":
        return BLUE;
      case "3":
        return YELLOW;
      case "2":
        return ORANGE;
      default:
        return RED;
    }
  });
  const chartData = {
    labels: appetiteTitles,
    datasets: [
      {
        label: "Submissions",
        data: sortedValues,
        backgroundColor: appetiteColors.map(
          (palette) => palette.backgroundColor
        ),
        borderColor: appetiteColors.map((palette) => palette.borderColor),
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ maxHeight: "260px" }}
    />
  );
};

const BrokerageChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
  specialtyReports,
  selectedYear,
  selectedUsers,
  allUsers,
}) => {
  // Helper function to get month name from a date string
  const getMonthName = (date: string) => {
    return new Date(date).toLocaleString("default", { month: "long" });
  };

  // Get the last three months
  const now = new Date();
  const lastThreeMonths = Array.from({ length: 3 }, (_, i) => {
    const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
    return month.toLocaleString("default", { month: "long" });
  }).reverse();

  // Organize the data by broker and month
  const brokerDataForCurrentYear: { [key: string]: { [key: string]: number } } =
    {};
  const filteredReports = filterBySelectedUsers(
    specialtyReports,
    selectedUsers
  );
  filteredReports.forEach((report) => {
    const brokerName = report.report_metadata.company_info.broker_name;
    const reportMonth = getMonthName(report.created_at_ts);

    if (lastThreeMonths.includes(reportMonth)) {
      if (!brokerDataForCurrentYear[brokerName]) {
        brokerDataForCurrentYear[brokerName] = {};
      }
      if (!brokerDataForCurrentYear[brokerName][reportMonth]) {
        brokerDataForCurrentYear[brokerName][reportMonth] = 0;
      }
      brokerDataForCurrentYear[brokerName][reportMonth] += 1;
    }
  });

  const generateDataForYear = (year: string) => {
    const monthInfo: {
      [key: string]: {
        [key: string]: number;
      };
    } = {};
    monthInfo["rtspecialty"] = {};
    monthInfo["marsh"] = {};
    monthInfo["wtwco"] = {};
    monthInfo["amwins"] = {};
    for (let i = 0; i < lastThreeMonths.length; i++) {
      const month = lastThreeMonths[i];

      if (year === "2024") {
        if (i === 0) {
          monthInfo["rtspecialty"][month] = modifyTestDataForUsers(
            25,
            selectedUsers,
            allUsers
          );
          monthInfo["marsh"][month] = modifyTestDataForUsers(
            50,
            selectedUsers,
            allUsers
          );
        } else if (i === 1) {
          monthInfo["rtspecialty"][month] = modifyTestDataForUsers(
            30,
            selectedUsers,
            allUsers
          );
          monthInfo["marsh"][month] = modifyTestDataForUsers(
            35,
            selectedUsers,
            allUsers
          );
          monthInfo["amwins"][month] = modifyTestDataForUsers(
            40,
            selectedUsers,
            allUsers
          );
        } else if (i === 2) {
          monthInfo["marsh"][month] = modifyTestDataForUsers(
            54,
            selectedUsers,
            allUsers
          );
          monthInfo["wtwco"][month] = modifyTestDataForUsers(
            20,
            selectedUsers,
            allUsers
          );
        }
      } else if (year === "2023") {
        if (i === 0) {
          monthInfo["amwins"][month] = modifyTestDataForUsers(
            40,
            selectedUsers,
            allUsers
          );
          monthInfo["marsh"][month] = modifyTestDataForUsers(
            55,
            selectedUsers,
            allUsers
          );
        } else if (i === 1) {
          monthInfo["rtspecialty"][month] = modifyTestDataForUsers(
            30,
            selectedUsers,
            allUsers
          );
          monthInfo["amwins"][month] = modifyTestDataForUsers(
            40,
            selectedUsers,
            allUsers
          );
        } else if (i === 2) {
          monthInfo["marsh"][month] = modifyTestDataForUsers(
            54,
            selectedUsers,
            allUsers
          );
          monthInfo["wtwco"][month] = modifyTestDataForUsers(
            20,
            selectedUsers,
            allUsers
          );
          monthInfo["rtspecialty"][month] = modifyTestDataForUsers(
            35,
            selectedUsers,
            allUsers
          );
        }
      }
    }
    return monthInfo;
  };

  const brokerDataByYear: {
    [key: string]: {
      [key: string]: {
        [key: string]: number;
      };
    };
  } = {
    "2025": brokerDataForCurrentYear,
    "2024": generateDataForYear("2024"),
    "2023": generateDataForYear("2023"),
  };

  const brokerData = brokerDataByYear[selectedYear.code];

  // Generate chartData with labels for the last three months
  const chartData = {
    labels: lastThreeMonths,
    datasets: Object.keys(brokerData).map((broker, index) => {
      const color = colorPalette[index % colorPalette.length];
      return {
        type: "bar",
        label: broker,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor,
        borderWidth: 1,
        data: lastThreeMonths.map((month) => brokerData[broker][month] || 0),
      };
    }),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};

const BusinessTypeByBrokerChart: React.FC<ChartProps> = ({
  selectedYear,
  selectedUsers,
  allUsers,
}) => {
  const dataByYear: { [key: string]: { [key: string]: number | string }[] } = {
    "2023": [
      {
        brokerEmail: "jeff@amwins.com",
        bindPercentage: 75,
        "Appliance; Air Conditioning; Janitorial": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
        "Commercial / Industrial specialty trades": modifyTestDataForUsers(
          7,
          selectedUsers,
          allUsers
        ),
        "Office buildings; Commercial / Retail / Mercantile LROs":
          modifyTestDataForUsers(3, selectedUsers, allUsers),
        "Automobile Parts": modifyTestDataForUsers(5, selectedUsers, allUsers),
      },
      {
        brokerEmail: "sarah@marsh.com",
        bindPercentage: 82,
        "Metal Erection - Decorative; Commercial Roofing":
          modifyTestDataForUsers(6, selectedUsers, allUsers),
        "Commercial Occupancy Condominiums; Vacant buildings; Vacant Land":
          modifyTestDataForUsers(2, selectedUsers, allUsers),
        "Single-family rental dwellings": modifyTestDataForUsers(
          8,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "michael@wtwco.com",
        bindPercentage: 68,
        "Machinery / Equipment (new); Beverage": modifyTestDataForUsers(
          5,
          selectedUsers,
          allUsers
        ),
        "Restaurants with <30% liquor; Risks with up to 50% liquor":
          modifyTestDataForUsers(3, selectedUsers, allUsers),
        "Truckers GL (primary only)": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "lisa@amwins.com",
        bindPercentage: 79,
        "Commercial / Industrial; Metal Goods products; Machine Shops":
          modifyTestDataForUsers(7, selectedUsers, allUsers),
        "Low-Rise Hotels with limited amenities": modifyTestDataForUsers(
          2,
          selectedUsers,
          allUsers
        ),
        "Automotive parts (non-critical); Valve products":
          modifyTestDataForUsers(5, selectedUsers, allUsers),
      },
      {
        brokerEmail: "david@wtwco.com",
        bindPercentage: 71,
        Motels: modifyTestDataForUsers(3, selectedUsers, allUsers),
        "Consumer; Sporting Goods products": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
        "Machinery / Equipment (used); Sporting Goods": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "anna@marsh.com",
        bindPercentage: 85,
        "Risks with >50% liquor": modifyTestDataForUsers(
          2,
          selectedUsers,
          allUsers
        ),
        "Metal Erection - Structural; Residential Roofing":
          modifyTestDataForUsers(5, selectedUsers, allUsers),
        "Condominiums; Mobile home parks;": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
      },
    ],
    "2024": [
      {
        brokerEmail: "jeff@amwins.com",
        bindPercentage: 78,
        "Appliance; Air Conditioning; Janitorial": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
        "Commercial / Industrial specialty trades": modifyTestDataForUsers(
          8,
          selectedUsers,
          allUsers
        ),
        "Office buildings; Commercial / Retail / Mercantile LROs":
          modifyTestDataForUsers(4, selectedUsers, allUsers),
      },
      {
        brokerEmail: "sarah@marsh.com",
        bindPercentage: 85,
        "Metal Erection - Decorative; Commercial Roofing":
          modifyTestDataForUsers(7, selectedUsers, allUsers),
        "Commercial Occupancy Condominiums; Vacant buildings; Vacant Land":
          modifyTestDataForUsers(3, selectedUsers, allUsers),
        "Single-family rental dwellings": modifyTestDataForUsers(
          9,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "michael@wtwco.com",
        bindPercentage: 72,
        "Machinery / Equipment (new); Beverage": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
        "Restaurants with <30% liquor; Risks with up to 50% liquor":
          modifyTestDataForUsers(4, selectedUsers, allUsers),
        "Truckers GL (primary only)": modifyTestDataForUsers(
          5,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "lisa@amwins.com",
        bindPercentage: 83,
        "Commercial / Industrial; Metal Goods products; Machine Shops":
          modifyTestDataForUsers(8, selectedUsers, allUsers),
        "Low-Rise Hotels with limited amenities": modifyTestDataForUsers(
          3,
          selectedUsers,
          allUsers
        ),
        "Automotive parts (non-critical); Valve products":
          modifyTestDataForUsers(6, selectedUsers, allUsers),
      },
      {
        brokerEmail: "david@wtwco.com",
        bindPercentage: 75,
        Motels: modifyTestDataForUsers(4, selectedUsers, allUsers),
        "Consumer; Sporting Goods products": modifyTestDataForUsers(
          7,
          selectedUsers,
          allUsers
        ),
        "Machinery / Equipment (used); Sporting Goods": modifyTestDataForUsers(
          5,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "anna@marsh.com",
        bindPercentage: 88,
        "Risks with >50% liquor": modifyTestDataForUsers(
          3,
          selectedUsers,
          allUsers
        ),
        "Metal Erection - Structural; Residential Roofing":
          modifyTestDataForUsers(6, selectedUsers, allUsers),
        "Condominiums; Mobile home parks;": modifyTestDataForUsers(
          5,
          selectedUsers,
          allUsers
        ),
      },
    ],
    "2025": [
      {
        brokerEmail: "jeff@amwins.com",
        bindPercentage: 82,
        "Appliance; Air Conditioning; Janitorial": modifyTestDataForUsers(
          7,
          selectedUsers,
          allUsers
        ),
        "Commercial / Industrial specialty trades": modifyTestDataForUsers(
          9,
          selectedUsers,
          allUsers
        ),
        "Office buildings; Commercial / Retail / Mercantile LROs":
          modifyTestDataForUsers(5, selectedUsers, allUsers),
      },
      {
        brokerEmail: "sarah@marsh.com",
        bindPercentage: 89,
        "Metal Erection - Decorative; Commercial Roofing":
          modifyTestDataForUsers(8, selectedUsers, allUsers),
        "Commercial Occupancy Condominiums; Vacant buildings; Vacant Land":
          modifyTestDataForUsers(4, selectedUsers, allUsers),
        "Single-family rental dwellings": modifyTestDataForUsers(
          10,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "michael@wtwco.com",
        bindPercentage: 76,
        "Machinery / Equipment (new); Beverage": modifyTestDataForUsers(
          7,
          selectedUsers,
          allUsers
        ),
        "Restaurants with <30% liquor; Risks with up to 50% liquor":
          modifyTestDataForUsers(5, selectedUsers, allUsers),
        "Truckers GL (primary only)": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "lisa@amwins.com",
        bindPercentage: 87,
        "Commercial / Industrial; Metal Goods products; Machine Shops":
          modifyTestDataForUsers(9, selectedUsers, allUsers),
        "Low-Rise Hotels with limited amenities": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
        "Automotive parts (non-critical); Valve products":
          modifyTestDataForUsers(7, selectedUsers, allUsers),
      },
      {
        brokerEmail: "david@wtwco.com",
        bindPercentage: 79,
        Motels: modifyTestDataForUsers(5, selectedUsers, allUsers),
        "Consumer; Sporting Goods products": modifyTestDataForUsers(
          8,
          selectedUsers,
          allUsers
        ),
        "Machinery / Equipment (used); Sporting Goods": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
      },
      {
        brokerEmail: "anna@marsh.com",
        bindPercentage: 91,
        "Risks with >50% liquor": modifyTestDataForUsers(
          4,
          selectedUsers,
          allUsers
        ),
        "Metal Erection - Structural; Residential Roofing":
          modifyTestDataForUsers(7, selectedUsers, allUsers),
        "Condominiums; Mobile home parks;": modifyTestDataForUsers(
          6,
          selectedUsers,
          allUsers
        ),
      },
    ],
  };
  const data = dataByYear[selectedYear.code];

  const allIndustries = new Set<string>();
  data.forEach((broker) => {
    Object.keys(broker).forEach((key) => {
      if (key !== "brokerEmail" && key !== "bindPercentage") {
        allIndustries.add(key);
      }
    });
  });

  // Create column definitions
  const industryColumns = Array.from(allIndustries).map((industry) => ({
    field: industry,
    header: industry,
    body: (rowData: { [key: string]: number }) => {
      // Calculate percentage for this industry
      const industryValue = rowData[industry] || 0;
      const totalSubmissions = Object.keys(rowData)
        .filter((key) => key !== "brokerEmail" && key !== "bindPercentage")
        .reduce((sum, key) => sum + (rowData[key] || 0), 0);

      const percentage = totalSubmissions
        ? ((industryValue / totalSubmissions) * 100).toFixed(1)
        : "0";

      return `${percentage}%`;
    },
  }));

  return (
    <DataTable value={data} scrollHeight="270px">
      <Column header="Broker" field="brokerEmail" />
      <Column
        header="Bind Percentage"
        field="bindPercentage"
        body={(brokerInfo: { [key: string]: string | number }) => (
          <div>{`${brokerInfo.bindPercentage}%`}</div>
        )}
      />
      {industryColumns.map((col) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={col.body}
        />
      ))}
    </DataTable>
  );
};
