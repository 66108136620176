import { Panel } from "primereact/panel";
import { SafeReportResponse } from "./Conversion";

import * as client from "../../client";
import { Card } from "primereact/card";
import styles from "./LossControlReports.module.css";
import { formatAbbreviatedMoney } from "./Money";

const formatShortAnswer = (text: string): string => {
  const asDollars = Number(text.replaceAll(",", "").replaceAll("$", ""));
  if (!isNaN(asDollars)) {
    return formatAbbreviatedMoney(Math.round(asDollars));
  }
  return text;
};

const LossControlReport = ({
  report,
}: {
  report: client.LossControlReport;
}) => {
  const header = report.entity_name
    ? `${report.entity_name} (${report.filename})`
    : report.filename;

  return (
    <Panel header={header} toggleable>
      <div className={styles.report}>
        {Object.entries(report.fields).map(([field, answer]) => {
          return (
            <div key={field}>
              <div className={styles.summary}>
                <b>{field}</b>:{" "}
                {answer?.short_answer
                  ? field !== "NAICS"
                    ? formatShortAnswer(answer.short_answer)
                    : answer.short_answer
                  : "Not found"}
              </div>
              {answer ? (
                <div className={styles.explanation}>
                  <i>{answer?.long_answer}</i>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </Panel>
  );
};

type LossControlReportsProps = {
  forPrint?: boolean;
  isCasualty?: boolean;
  setMapVisible?: (visible: boolean) => void;
  specialtyPropertyInfo: SafeReportResponse;
};
export const LossControlReports: React.FC<LossControlReportsProps> = ({
  specialtyPropertyInfo,
}) => {
  const reports = specialtyPropertyInfo.report_json.loss_control_reports ?? [];
  return (
    <Card title="Loss Control Reports">
      {reports.length > 0 ? (
        reports.map((report) => (
          <LossControlReport key={report.filename} report={report} />
        ))
      ) : (
        <>No reports found</>
      )}
    </Card>
  );
};
