import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import * as client from "../../../client";
import { applyOverridesToAddressField } from "../Conversion";
import { Button } from "primereact/button";
import styles from "./BulkEditModal.module.css";
import { InputTextarea } from "primereact/inputtextarea";
import { generateBulkEdit } from "../../../api/routes";
import { Panel } from "primereact/panel";
import { useUpdateAddresses } from "../data";

export type BulkEditModalState =
  | { field: keyof client.RawAddress; fieldName: string }
  | undefined;

export const BulkEditModal = ({
  state,
  onHide,
  report,
  isLightMode,
}: {
  state: BulkEditModalState;
  onHide: () => void;
  report: client.CommercialReport;
  isLightMode: boolean;
}) => {
  const [instructions, setInstructions] = useState("");
  const [preview, setPreview] = useState<[client.Address, client.Address][]>(
    []
  );
  const [working, setWorking] = useState(false);
  const modeClass = isLightMode ? styles.light : styles.dark;
  const updateAddresses = useUpdateAddresses(report.report_id);

  useEffect(() => {
    if (!state) {
      setInstructions("");
      setPreview([]);
    }
  }, [state]);

  const computePreview = async () => {
    //todo
    if (state) {
      setWorking(true);
      try {
        const [before, after] = await generateBulkEdit(
          report.report_id,
          state.field,
          instructions
        );
        setPreview(before.map((b, i) => [b, after[i]]));
      } catch (e) {
        console.error(e);
      }
      setWorking(false);
    }
  };

  const removePreviewRowByAddressId = (addressId: string) => {
    setPreview((preview) => preview.filter(([p]) => p.address_id != addressId));
  };

  const disableButtons = working || updateAddresses.isPending;

  return (
    <Dialog
      visible={!!state}
      onHide={onHide}
      header={`Smart-Edit ${state?.fieldName} Values`}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <InputTextarea
            name="Parsing instructions"
            id="instructions"
            value={instructions}
            onChange={(e) => setInstructions(e.currentTarget.value)}
            className={styles.input}
            placeholder="Example: If multiple stories are provided, use the first one"
          ></InputTextarea>
          <Button onClick={computePreview} disabled={disableButtons}>
            Preview
          </Button>
        </div>
        {preview.length > 0 ? (
          <Panel
            header={`${
              preview.length > 1 ? preview.length + " addresses" : "1 address"
            } are affected by your instructions`}
          >
            <table className={styles.rowContainer}>
              <thead>
                <th className={styles.originalHeader}>Input</th>
                <th>Old</th>
                <th>New</th>
              </thead>
              {state &&
                preview.map(([a, b]) => {
                  const original =
                    a.private_original_values[state.field] ?? "(empty)";
                  const before = applyOverridesToAddressField(a, state.field);
                  const after = applyOverridesToAddressField(b, state.field);
                  return (
                    <tr key={a.address_id}>
                      <td className={`${styles.original} ${modeClass}`}>
                        {original}
                      </td>
                      <td
                        className={`${
                          before !== after ? styles.before : styles.cleared
                        } ${modeClass}`}
                      >
                        {before}
                      </td>
                      <td
                        className={`${
                          before !== after ? styles.after : styles.cleared
                        } ${modeClass}`}
                      >
                        {after}
                      </td>
                      <td>
                        <Button
                          icon="pi pi-times"
                          iconPos="right"
                          onClick={() =>
                            removePreviewRowByAddressId(a.address_id)
                          }
                          tooltip="Remove this change"
                          text
                          disabled={disableButtons}
                        />
                      </td>
                    </tr>
                  );
                })}
            </table>
          </Panel>
        ) : null}
        <div className={styles.row}>
          <Button
            onClick={async () => {
              await updateAddresses.mutateAsync({
                addresses: preview.map(([, after]) => after),
              });
              onHide();
            }}
            disabled={preview.length === 0 || disableButtons}
            severity="danger"
          >
            Apply Changes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
