import { ReactNode } from "react";
import article1Image from "./articleGraphic1.png";
import article2Image from "./articleGraphic2.png";
import { DataTableArticle1, DataTableArticle2 } from "./ArticleContext";

type ContentPiece =
  | {
      contentType: "text" | "image";
      src: string;
    }
  | {
      contentType: "component";
      src: () => ReactNode;
    };

export type ArticleContent = {
  title: string;
  content: ContentPiece[];
  mainImgSrc: string;
  id: string;
};

export const articleContents: ArticleContent[] = [
  {
    title: "Portfolio Underwriting & Concentration of Risk Part I",
    content: [
      {
        contentType: "text",
        src: "When I talk to folks in the insurance industry, I frequently hear about portfolio underwriting and a shift in the way that underwriters think about and manage risk.  This shift from frontline level to portfolio level requires underwriters to manage risk as a book of business rather than an individual risk.  One of the key things to consider when practicing portfolio underwriting is the CAT reinsurance costs.",
      },
      {
        contentType: "text",
        src: "When an insurance company purchases reinsurance, each layer in the tower will have a different loss on line and rate on line.  As you move higher in the tower, the reinsurance margin (rate on line / loss on line) should increase monotonically.  Reinsurers need to receive a higher premium to compensate them for the higher cost of capital required to protect against infrequent high severity events.  An example of a small reinsurance tower protecting against losses up to 800MM is shown below.  As you can see, the highest layer (400 x 400) has a reinsurance margin that is much higher than the first layer (15 x 10).",
      },
      {
        contentType: "component",
        src: DataTableArticle1,
      },
      {
        contentType: "text",
        src: "When underwriters are determining what to charge for a large account, it’s common to calculate the AAL (average annual loss) using catastrophe modeling software like AIR and RMS.  This software will, like magic, spit out an AAL for a policy based on sophisticated models built by very smart people.  Underwriters will generally combine this with the attritional non-CAT loss and gross up with expenses to calculate a total premium.",
      },
      {
        contentType: "text",
        src: "However, to be a true portfolio underwriter, I contend that you need to think about the policy in relation to your entire book of business and concentration of risk.  As you can see from the table above, if you write a 1MM policy for a condo in Vermont with 300K in AAL and your insurance company does business all over the United States, you’re probably going to be in Layer 1, paying 390K in reinsurance. If, however, that same policy were in Florida with 300K in AAL, you might be in Layer 6 paying more like 690K in reinsurance costs due to the difference in reinsurance margin.",
      },
      {
        contentType: "text",
        src: "Stay tuned for the next article where we talk about how you can use ELT’s (event loss tables) from RMS to calculate your reinsurance costs in real time so you don’t undercharge high concentration of risk policies at the expense of low concentration of risk policies!",
      },
    ],
    mainImgSrc: article1Image,
    id: "7f8d3k9lx2pt6ywa",
  },
  {
    title: "Portfolio Underwriting & Concentration of Risk Part II",
    content: [
      {
        contentType: "text",
        src: "This piece continues the discussion around portfolio underwriting and concentration of risk. In the prior post we looked at how reinsurance markups vary depending on which layers of a reinsurance treaty the marginal AAL of a policy falls in. In this post, we will discuss how you can use RMS ELTs (event loss tables) to calculate the marginal impact to your book of business from writing a particular piece of business. To keep things simple, we’ll hold the rest of your book constant and assume you’re just making a single decision about whether to write this new piece of business given an already existing mix of business. If you were trying to determine fair pricing for your entire book of business one option would be to use this approach with Shapely values but to simplify the scenario and illustrate how this calculation can be performed with RMS ELTs we’ll pretend you just have a single policy in front of you and the decision is to write it or not.",
      },
      {
        contentType: "text",
        src: "The key here is to sum the total loss for a given event across your entire book of business. Then you can add the marginal loss from that event that would come from the new submission. Once you’ve done this you can compare those losses from the ELT to your reinsurance treaty to understand what the all in cost of writing that policy including reinsurance margin would be.",
      },
      {
        contentType: "component",
        src: DataTableArticle2,
      },
      {
        contentType: "text",
        src: "Assuming we are considering writing that condo in Florida vs. that condo in Vermont, we can see here that the marginal loss from the Florida condo would be 400MM-403MM and the marginal loss from the Vermont Condo would be 30MM-30.86MM. Both policies have an AAL of 300K but you end up paying a higher reinsurance margin on the FL condo vs. the VT condo since it is in a higher layer.",
      },
    ],
    mainImgSrc: article2Image,
    id: "lkahsjdfkas0d9ff",
  },
];
