import { Card } from "primereact/card";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import * as client from "../../../client";
import { formatAbbreviatedMoney, formatExactMoneyCents } from "../Money";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import styles from "./AddressCard.module.css";
import { Menu } from "primereact/menu";
import { downloadSov } from "../../../api/routes";
import { EditSovModal } from "./EditSovModal";
import { ColumnMappingsModal } from "./ColumnMappingsModal";
import { PantheonOnly } from "../../../dev";
import { useUpdateAddresses, useUpdateReport } from "../data";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { applyDeltasAsOverride, applyOverridesToAddress } from "../Conversion";
import { ThemeContext } from "../../../themes/Theme";
import { Tooltip } from "primereact/tooltip";
import {
  flagTextFor,
  LOW_CONFIDENCE_MINIMUM_SEVERITY,
  maxSeverityForAddressFlags,
  severityForFlag,
} from "./flags";
import {
  HIGHLIGHT_ERROR_DARK_MODE,
  HIGHLIGHT_ERROR_LIGHT_MODE,
  HIGHLIGHT_USER_OVERRIDE_DARK_MODE,
  HIGHLIGHT_USER_OVERRIDE_LIGHT_MODE,
  HIGHLIGHT_WARNING_DARK_MODE,
  HIGHLIGHT_WARNING_LIGHT_MODE,
} from "../dashboard/util";
import { isEqual } from "lodash";
import {
  Editor,
  MoneyEditor,
  OneLineStringEditor,
  StateEditor,
} from "./CellEditors";
import { downloadBlob } from "../../../util";
import { BulkEditModal, BulkEditModalState } from "./BulkEditModal";

type AddressCardProps = {
  specialtyPropertyInfo: client.ReportResponse;
  isCasualty?: boolean;
  setMapVisible?: (visible: boolean) => void;
};

const download = async (
  reportId: string,
  format: string,
  companyName: string
) => {
  // Download the zip file as a blob
  const blob = await downloadSov(reportId, format);
  const filename = `${companyName}_SOV_${format}.xlsx`;
  downloadBlob(filename, blob);
};

type CustomAction =
  | "open_edit_modal_construction"
  | "open_edit_modal_occupancy";

type SovColumnProps = {
  getValue: (data: client.RawAddress) => string | number | undefined | null;
  columnName: string;
  width: string;
  field?: keyof client.RawAddress;
  editor?: Editor;
  customAction?: CustomAction;
  bulkEdit?: boolean;
};

const columns: SovColumnProps[] = [
  {
    getValue: (data: client.RawAddress) => data.street_address,
    columnName: "Address",
    width: "200px",
    field: "street_address",
    editor: OneLineStringEditor,
  },
  {
    getValue: (data: client.RawAddress) => data.city,
    columnName: "City",
    width: "150px",
    field: "city",
    editor: OneLineStringEditor,
  },
  {
    getValue: (data: client.RawAddress) => data.state,
    columnName: "State",
    width: "100px",
    field: "state",
    editor: StateEditor,
  },
  {
    getValue: (data: client.RawAddress) => data.zip,
    columnName: "Zip",
    width: "75px",
    field: "zip",
    editor: OneLineStringEditor,
  },
  {
    getValue: (data: client.RawAddress) => data.country_code,
    columnName: "Country",
    width: "50px",
    field: "country_code",
    editor: OneLineStringEditor,
  },
  {
    getValue: (data: client.RawAddress) =>
      `${data.occupancy_code_type} ${data.occupancy_code}`,
    width: "75px",
    columnName: "Occupancy",
    field: "occupancy_code",
    customAction: "open_edit_modal_occupancy",
  },
  {
    getValue: (data: client.RawAddress) =>
      `${data.construction_code_type} ${data.construction_code}`,
    width: "75px",
    columnName: "Construction",
    field: "construction_code",
    customAction: "open_edit_modal_construction",
  },
  {
    getValue: (data: client.RawAddress) => data.year_built,
    columnName: "Year Built",
    width: "125px",
    field: "year_built",
    editor: OneLineStringEditor,
    bulkEdit: true,
  },
  {
    getValue: (data: client.RawAddress) => data.number_of_stories,
    columnName: "Num Stories",
    width: "125px",
    field: "number_of_stories",
    editor: OneLineStringEditor,
    bulkEdit: true,
  },
  {
    getValue: (data: client.RawAddress) => data.square_footage,
    columnName: "Square Footage",
    width: "150px",
    field: "square_footage",
    editor: OneLineStringEditor,
    bulkEdit: true,
  },
  {
    getValue: (data: client.RawAddress) =>
      data.building_value ? parseFloat(data.building_value) : undefined,
    columnName: "Building Value",
    width: "125px",
    field: "building_value",
    editor: MoneyEditor,
  },
  {
    getValue: (data: client.RawAddress) =>
      data.bpp_value ? parseFloat(data.bpp_value) : undefined,
    columnName: "BPP Value",
    width: "125px",
    field: "bpp_value",
    editor: MoneyEditor,
  },
  {
    getValue: (data: client.RawAddress) =>
      data.business_interruption_value
        ? parseFloat(data.business_interruption_value)
        : undefined,
    columnName: "BI Value",
    width: "125px",
    field: "business_interruption_value",
    editor: MoneyEditor,
  },
  {
    getValue: (data: client.RawAddress) => data.tiv,
    columnName: "TIV",
    width: "125px",
    field: "tiv",
    editor: MoneyEditor,
  },
];

const flaggedHighlightColor = (
  maxSeverity: number,
  isLightMode: boolean,
  hasOverride: boolean
) => {
  if (hasOverride) {
    if (isLightMode) {
      return HIGHLIGHT_USER_OVERRIDE_LIGHT_MODE;
    }
    return HIGHLIGHT_USER_OVERRIDE_DARK_MODE;
  }
  if (maxSeverity > LOW_CONFIDENCE_MINIMUM_SEVERITY) {
    if (isLightMode) {
      return HIGHLIGHT_ERROR_LIGHT_MODE;
    }
    return HIGHLIGHT_ERROR_DARK_MODE;
  }
  if (isLightMode) {
    return HIGHLIGHT_WARNING_LIGHT_MODE;
  }
  return HIGHLIGHT_WARNING_DARK_MODE;
};

type CellBodyProps = {
  columnInfo: SovColumnProps;
  fullAddress: client.Address;
  isLightMode: boolean;
  isUpdating: boolean;
  onClick?: () => void;
};

const CellBody: React.FC<CellBodyProps> = ({
  columnInfo,
  fullAddress,
  isLightMode,
  isUpdating,
  onClick,
}) => {
  const rawAddress = applyOverridesToAddress(fullAddress);
  let value = columnInfo.getValue(rawAddress);
  if (typeof value === "number") {
    const cents = Math.round(value * 100);
    value = formatExactMoneyCents(cents);
  }

  const hasOverride =
    !!columnInfo.field && !!fullAddress.private_overrides[columnInfo.field];
  const maxSeverity = maxSeverityForAddressFlags(fullAddress, columnInfo.field);
  const flagText = flagTextFor(fullAddress, columnInfo.field);

  return (
    <div
      className={styles.cellTextWrapper}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "unset" }}
    >
      <span
        className={styles.cellText}
        style={
          maxSeverity || hasOverride
            ? {
                backgroundColor: flaggedHighlightColor(
                  maxSeverity,
                  isLightMode,
                  hasOverride
                ),
                padding: "2px",
                opacity: isUpdating ? "0.5" : "1.0",
              }
            : {
                opacity: isUpdating ? "0.5" : "1.0",
              }
        }
        data-pr-tooltip={flagText}
        data-pr-showdelay={500}
      >
        {value}
      </span>
    </div>
  );
};

const FlaggedInput: React.FC<{
  columnInfo: SovColumnProps;
  children: React.ReactNode;
  address: client.Address;
  field: keyof client.RawAddress;
}> = ({ columnInfo, children, address, field }) => {
  let originalFieldValue = columnInfo.getValue(
    applyOverridesToAddress(address)
  );
  const hasOverride = address.private_overrides[field] !== undefined;
  if (typeof originalFieldValue === "number") {
    const cents = Math.round(originalFieldValue * 100);
    originalFieldValue = formatExactMoneyCents(cents);
  }

  const rawFlagText = flagTextFor(address, field);
  const flagText =
    !hasOverride && rawFlagText
      ? `Original value ${originalFieldValue} had the following issues:\n${rawFlagText}`
      : "";

  return (
    <div
      className={`flaggedInput ${styles.flaggedInput}`}
      data-pr-tooltip={flagText}
      data-pr-showdelay={500}
    >
      {children}
      <Tooltip target=".flaggedInput" position="left" />
    </div>
  );
};

// DataTable works best if the `field` prop can look up values on the data
// objects, but we need to pass additional data such as the full address object.
// This type represents a RawAddress with other data hidden in the __extras
// property
type RawAddressWithIndices = client.RawAddress & {
  __extras: {
    fullAddress: client.Address;
  };
};

export const AddressCard: React.FC<AddressCardProps> = ({
  specialtyPropertyInfo,
  isCasualty,
  setMapVisible,
}) => {
  const reportId = specialtyPropertyInfo.report_id;
  const sovs = specialtyPropertyInfo.report_json.sovs;
  const companyName =
    specialtyPropertyInfo.report_json.company_info.company_name;
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const updateReport = useUpdateReport(reportId);
  const updateAddresses = useUpdateAddresses(reportId);

  // Show enabled addresses
  const fullAddresses: RawAddressWithIndices[] = useMemo(() => {
    const unsorted = (
      updateReport.isPending ? updateReport.variables.sovs ?? sovs : sovs
    )
      .filter((sov) => sov.is_enabled)
      .flatMap((sov) =>
        sov.addresses.map((address) => ({
          ...applyOverridesToAddress(address),
          __extras: {
            fullAddress: address,
          },
        }))
      );
    unsorted.sort((a, b) => {
      const flagSeverityB = Object.values(b.__extras.fullAddress.private_flags)
        .flatMap((flags) => flags)
        .reduce((acc, flag) => acc + severityForFlag(flag), 0);
      const flagSeverityA = Object.values(a.__extras.fullAddress.private_flags)
        .flatMap((flags) => flags)
        .reduce((acc, flag) => acc + severityForFlag(flag), 0);

      return flagSeverityB - flagSeverityA;
    });
    return unsorted;
  }, [sovs, updateReport.isPending, updateReport.variables?.sovs]);

  const totalTIV = useMemo(
    () => fullAddresses.reduce((total, address) => total + address.tiv, 0),
    [fullAddresses]
  );
  const totalBuildingValue = useMemo(
    () =>
      fullAddresses.reduce(
        (total, address) =>
          total +
          (address.building_value && !isNaN(Number(address.building_value))
            ? Number(address.building_value)
            : 0),
        0
      ),
    [fullAddresses]
  );
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [columnModalVisible, setColumnModalVisible] = useState(false);
  const [bulkEditModalField, setBulkEditModalField] =
    useState<BulkEditModalState>(undefined);
  const isRerunning = updateReport.isPending;

  const [editModalActiveIndex, setEditModalActiveIndex] = useState<
    number | undefined
  >(undefined);
  const { tabInfo } = useParams<{ tabInfo?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (tabInfo) {
      if (tabInfo === "columns") {
        setColumnModalVisible(true);
      } else if (tabInfo === "construction") {
        setEditModalVisible(true);
        setEditModalActiveIndex(1);
      } else if (tabInfo === "occupancy" || tabInfo === "sheets") {
        setEditModalVisible(true);
        setEditModalActiveIndex(0);
      }
      navigate(
        ROUTES.DASHBOARD_REPORT(specialtyPropertyInfo.report_id, "sov"),
        { replace: true }
      );
    }
  }, [tabInfo]);

  const onEditComplete = (e: DataTableRowEditCompleteEvent) => {
    const { __extras, ...editedData } = e.newData as RawAddressWithIndices;
    // Just removing this value, it is otherwise unused.
    void __extras;

    const {
      __extras: { fullAddress: oldAddress },
    } = e.data as RawAddressWithIndices;

    const newAddress = applyDeltasAsOverride(oldAddress, editedData);
    if (!isEqual(newAddress, oldAddress)) {
      updateAddresses.mutateAsync({ addresses: [newAddress] });
    }
  };

  const onCustomAction = useCallback(
    (action: CustomAction) => () => {
      switch (action) {
        case "open_edit_modal_occupancy":
          setEditModalVisible(true);
          setEditModalActiveIndex(0);
          break;
        case "open_edit_modal_construction":
          setEditModalVisible(true);
          setEditModalActiveIndex(1);
          break;
      }
    },
    []
  );

  return (
    <Card
      title="Statement of Values"
      subTitle={
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitleContentsContainerVertical}>
            <span>
              {isCasualty
                ? `Total Building Value: ${formatAbbreviatedMoney(
                    totalBuildingValue
                  )}`
                : `Total Insured Value: ${formatAbbreviatedMoney(totalTIV)}`}
            </span>
            <span>{`Location Count: ${fullAddresses.length}`}</span>
          </div>
          <div className={styles.subtitleContentsContainer}>
            {setMapVisible ? (
              <Button
                text
                raised
                severity="secondary"
                icon="pi pi-map"
                label="Show map"
                onClick={() => setMapVisible(true)}
              />
            ) : null}
            <DownloadActionMenu
              onDownloadRMS={() => download(reportId, "rms", companyName)}
              onDownloadHX={() => download(reportId, "hx", companyName)}
            />
            <Button
              text
              raised
              label="Edit"
              severity="secondary"
              icon="pi pi-file-edit"
              onClick={() => setEditModalVisible(true)}
            />
            {sovs.some(
              (sov) => sov.column_mappings && sov.all_document_headers
            ) && (
              <Button
                text
                raised
                label="Columns"
                severity="secondary"
                icon="pi pi-arrow-right"
                onClick={() => setColumnModalVisible(true)}
              />
            )}
            <PantheonOnly>
              <Button
                text
                raised
                label="Re-process"
                severity="danger"
                icon={isRerunning ? "pi pi-spin pi-spinner" : "pi pi-replay"}
                onClick={() => updateReport.mutate({ rerunReport: true })}
                disabled={isRerunning}
              />
            </PantheonOnly>
          </div>
        </div>
      }
    >
      <Tooltip target={"." + styles.cellText} position="left" />
      <div className="pantheon-small-table">
        <DataTable
          value={fullAddresses}
          paginator
          rows={50}
          className={styles.table}
          editMode="row"
          onRowEditComplete={onEditComplete}
        >
          <Column
            rowEditor
            bodyStyle={{ textAlign: "center" }}
            headerStyle={{ minWidth: "8rem", width: "8rem" }}
          />
          {columns.map((columnInfo, index) => {
            const EditorComponent = columnInfo.editor;
            const field = columnInfo.field;

            const editor =
              EditorComponent && field
                ? (options: ColumnEditorOptions): React.ReactNode => {
                    const fullAddress = (
                      options.rowData as RawAddressWithIndices
                    ).__extras.fullAddress;
                    const hasOverride =
                      fullAddress.private_overrides[field] !== undefined;
                    const maxSeverity = maxSeverityForAddressFlags(
                      fullAddress,
                      field
                    );
                    const additionalStyles =
                      maxSeverity || hasOverride
                        ? {
                            boxShadow: `0 0 0 2px ${flaggedHighlightColor(
                              maxSeverity,
                              isLightMode,
                              hasOverride
                            )}`,
                          }
                        : {};
                    return (
                      <FlaggedInput
                        columnInfo={columnInfo}
                        address={fullAddress}
                        field={field}
                      >
                        <EditorComponent
                          options={options}
                          address={options.rowData}
                          disabled={updateReport.isPending}
                          additionalStyles={additionalStyles}
                        />
                      </FlaggedInput>
                    );
                  }
                : null;
            return (
              <Column
                editor={editor}
                key={index}
                field={field}
                headerStyle={{
                  height: "44px",
                  width: columnInfo.width,
                  minWidth: columnInfo.width,
                }}
                header={
                  <div className={styles.header}>
                    {columnInfo.columnName}{" "}
                    {columnInfo.bulkEdit && columnInfo.field ? (
                      <span
                        role="button"
                        className={`pi pi-pencil ${styles.editButton}`}
                        onClick={() =>
                          columnInfo.field &&
                          setBulkEditModalField({
                            field: columnInfo.field,
                            fieldName: columnInfo.columnName,
                          })
                        }
                      />
                    ) : null}
                  </div>
                }
                body={(data: RawAddressWithIndices) => (
                  <CellBody
                    columnInfo={columnInfo}
                    fullAddress={data.__extras.fullAddress}
                    isLightMode={isLightMode}
                    isUpdating={
                      updateAddresses.isPending &&
                      updateAddresses.variables.addresses.some(
                        (a) =>
                          a.address_id === data.__extras.fullAddress.address_id
                      )
                    }
                    onClick={
                      columnInfo.customAction
                        ? onCustomAction(columnInfo.customAction)
                        : undefined
                    }
                  />
                )}
              />
            );
          })}
        </DataTable>
      </div>
      <EditSovModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        sovs={sovs}
        reportId={reportId}
        initialActiveIndex={editModalActiveIndex}
      />
      <ColumnMappingsModal
        visible={columnModalVisible}
        setVisible={setColumnModalVisible}
        sovs={sovs}
        reportId={reportId}
      />
      <BulkEditModal
        state={bulkEditModalField}
        onHide={() => setBulkEditModalField(undefined)}
        report={specialtyPropertyInfo.report_json}
        isLightMode={isLightMode}
      />
    </Card>
  );
};

type DownloadActionMenuProps = {
  onDownloadRMS: () => void;
  onDownloadHX: () => void;
};

const DownloadActionMenu: React.FC<DownloadActionMenuProps> = ({
  onDownloadRMS,
  onDownloadHX,
}) => {
  const menu = useRef<Menu>(null);
  const items = [
    {
      label: "Download",
      items: [
        {
          label: "RMS Sheet",
          command: onDownloadRMS,
        },
        {
          label: "HX Sheet",
          command: onDownloadHX,
        },
      ],
    },
  ];

  return (
    <div>
      <Menu model={items} popup ref={menu} />
      <Button
        text
        raised
        label="Download"
        severity="secondary"
        icon="pi pi-file-excel"
        onClick={(e) => {
          menu.current?.toggle(e);
        }}
      />
    </div>
  );
};
