import { Navigate, useParams } from "react-router-dom";
import { articleContents } from "./article-contents/articlesContent";
import styles from "./FullArticle.module.css";
import { ROUTES } from "../../routes";

export const FullArticle = () => {
  const { id } = useParams<{ id: string }>();

  const articleContent = articleContents.find((content) => content.id === id);

  // This should never happen
  if (articleContent === undefined) {
    return <Navigate to={ROUTES.ARTICLES} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <img src={articleContent.mainImgSrc} />
        <div className={styles.authorContainer}>
          <img
            className={styles.authorImg}
            src={
              "https://media.licdn.com/dms/image/v2/D4E03AQF20DcvJ7OLZQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1711984724453?e=1744848000&v=beta&t=vb18t7wGiLPBTBx7JQCwgM6lizcwhvgEvMORZOrepuU"
            }
          />
          <span
            className={styles.articleText}
            style={{ color: "var(--text-color-secondary)" }}
          >
            Greg Karabinos
          </span>
        </div>
        <h2 className={styles.headerText}>{articleContent.title}</h2>
        {articleContent.content.map((contentPiece, index) => (
          <div key={index}>
            {contentPiece.contentType === "text" && (
              <span className={styles.articleText}>{contentPiece.src}</span>
            )}
            {contentPiece.contentType === "component" && contentPiece.src()}
          </div>
        ))}
        <div style={{ height: "48px", flexShrink: 0 }} />
      </div>
    </div>
  );
};
