export const formatExactMoneyCents = (
  totalCents: number,
  withDollarSign: boolean = true
): string => {
  const dollars = Math.floor(totalCents / 100).toString();
  const cents = Math.floor(totalCents % 100);
  const startingCharacter = withDollarSign ? "$" : "";

  const parts = [];
  for (let i = dollars.length; i > 0; i -= 3) {
    parts.unshift(dollars.substring(Math.max(0, i - 3), i));
  }

  if (cents == 0) {
    return `${startingCharacter}${parts.join(",")}`;
  }
  return `${startingCharacter}${parts.join(",")}.${cents}`;
};

const formatExactMoneyDollars = (
  dollars: number,
  withDollarSign: boolean = true
): string => {
  return formatExactMoneyCents(dollars * 100, withDollarSign);
};

// Not really significant digits in the classical sense, but rather the maximum
// number of non-zero digits we ideally show for a given value.
const SIGNIFICANT_DIGITS = 3;

// At what `threshold` should we add `unit` and remove `dropDigits` off the right.
const DIGIT_LENGTH_TO_HANDLING = [
  {
    threshold: 10,
    unit: "B",
    dropDigits: 9,
  },
  {
    threshold: 7,
    unit: "MM",
    dropDigits: 6,
  },
  {
    threshold: 4,
    unit: "K",
    dropDigits: 3,
  },
] as const;

const parse = (x: string): number => {
  return x === "" ? 0 : parseInt(x, 10);
};

export const formatAbbreviatedMoney = (
  dollars: number,
  withDollarSign: boolean = true
): string => {
  // XXX being defensive against fractional values passed in during demo due to random(), etc.
  dollars = Math.round(dollars);

  for (const { threshold, unit, dropDigits } of DIGIT_LENGTH_TO_HANDLING) {
    const shift = Math.min(
      dollars.toString().length - SIGNIFICANT_DIGITS,
      dropDigits
    );
    const roundedDollars =
      dollars.toString().length > 3
        ? Math.round(dollars / Math.pow(10, shift)) * Math.pow(10, shift)
        : dollars;
    const length = roundedDollars.toString().length;

    if (length >= threshold) {
      const significand = roundedDollars.toString();
      const dotPlace = length - dropDigits;

      const whole = significand.substring(0, dotPlace);
      let partial = significand.substring(
        dotPlace,
        Math.max(SIGNIFICANT_DIGITS, length - dropDigits)
      );

      // Note this may drop some "significant digits" in the sake of cleaner output in the UI.
      while (partial.endsWith("0")) {
        partial = partial.substring(0, partial.length - 1);
      }

      const formattedWhole = formatExactMoneyDollars(
        parse(whole),
        withDollarSign
      );
      const formattedPartial = partial ? "." + partial : "";
      return `${formattedWhole}${formattedPartial}${unit}`;
    }
  }
  return formatExactMoneyDollars(dollars, withDollarSign);
};

export const AbbreviatedMoney = ({ dollars }: { dollars: number }) => {
  return <>{formatAbbreviatedMoney(dollars)}</>;
};

export const ExactMoney = ({ cents }: { cents: number }) => {
  return <>{formatExactMoneyCents(cents)}</>;
};
