import { useMemo } from "react";

export const APP_VERSION = process.env.REACT_APP_VERSION;
const BUILD_CHANNEL = process.env.REACT_APP_BUILD_CHANNEL;

export const isRunningLocally = () => {
  return process.env.NODE_ENV !== "production";
};

export const buildChannel = () => {
  if (BUILD_CHANNEL === "prod") {
    return "prod";
  }
  if (BUILD_CHANNEL === "canary") {
    return "canary";
  }
  if (BUILD_CHANNEL === "dev-vm") {
    return "dev-vm";
  }
  if (BUILD_CHANNEL === "xenia") {
    return "xenia";
  }
  return "dev";
};

export const sleepMs = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isDefined = <X>(x: X | undefined): x is X => x !== undefined;

export const randomAlphaChar = () =>
  "abcdefghijklmnopqrstuvwxyz"[Math.floor(Math.random() * 26)];
export const useRandomId = () =>
  useMemo(() => Array.from({ length: 8 }, randomAlphaChar).join(""), []);

type UserNamePart = { full_name?: string | null; email: string };
export function displayNameForUser(user: UserNamePart): string;
export function displayNameForUser(
  user: UserNamePart | undefined
): string | undefined;
export function displayNameForUser(
  user: UserNamePart | undefined
): string | undefined {
  return user ? user.full_name ?? user.email : undefined;
}

export const downloadBlob = (name: string, blob: Blob) => {
  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and trigger a download
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
