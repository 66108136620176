import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { downloadReportFile } from "../../../api/routes";
import { ProgressSpinner } from "primereact/progressspinner";

export const PDFPreview = ({
  reportId,
  visible,
  onHide,
  filename,
  page,
}: {
  reportId: string;
  visible: boolean;
  onHide: () => void;
  filename: string | undefined;
  page: number | undefined;
}) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [cachedUrl, setCachedUrl] = useState<
    { filename: string; url: string } | undefined
  >(undefined);
  const [windowSize, setWindowSize] = useState<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    (async () => {
      if (visible && filename) {
        let url: string | undefined;
        if (cachedUrl?.filename === filename) {
          url = cachedUrl.url;
        } else {
          const blob = await downloadReportFile(reportId, filename);
          url = await blobToBase64(blob);
          if (url) {
            setCachedUrl({ filename, url });
          }
        }

        if (url) {
          if (page) {
            url += `#page=${page}`;
          }
          setUrl(url);
        }
      }
    })();
  }, [visible, filename, cachedUrl]);

  useEffect(() => {
    const callback = () =>
      setWindowSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, []);

  return (
    <Dialog
      header={filename}
      visible={visible}
      onHide={() => {
        setUrl(undefined);
        onHide();
      }}
      draggable={false}
    >
      <div style={{ display: "flex" }}>
        {visible && filename ? (
          url ? (
            <iframe
              width={windowSize[0] - 100}
              height={windowSize[1] - 200}
              src={url}
            ></iframe>
          ) : (
            <ProgressSpinner />
          )
        ) : null}
      </div>
    </Dialog>
  );
};

const blobToBase64 = async (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(typeof reader.result);
      }
    };
    reader.readAsDataURL(blob);
  });
};
