import * as client from "../../../client";

export const deductibleAndNetIncurredDoNotSumToGrossLoss = (
  loss: client.Loss
) => {
  // Only do the calculation if we have all values
  if (loss.deductible && loss.net_incurred && loss.ground_up_loss) {
    if (
      loss.ground_up_loss.value <= loss.deductible.value &&
      loss.net_incurred.value === 0
    ) {
      return false;
    }

    return (
      Math.round(loss.deductible.value + loss.net_incurred.value) !==
      Math.round(loss.ground_up_loss.value)
    );
  }

  return false;
};
