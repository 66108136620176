export const POLICY_EXCLUSIONS_BY_QUESTION_ID: { [key: string]: string } = {
  property_1: "Air supported structures",
  property_2: "Aircraft or watercraft",
  property_3: "Animals",
  property_4: "Bridges, roadways, streets, walks or any other paved surfaces",
  property_5: "Currency, money, securities",
  property_6: "Dams or dikes",
  property_7: "Docks piers or wharves",
  property_8: "Evidence of debt, notes",
  property_9: "Furs, jewelry, precious or semi precious stones",
  property_10: "Growing crops or standing timber",
  property_11: "Land",
  property_12: "Off-shore drilling rigs or platforms",
  property_13: "Outdoor plants, trees, shrubs or lawns",
  property_14: "Precious Metals",
  property_15:
    "Property in transit, except as otherwise provided by this Policy",
  property_16:
    "Property sold under conditional sale, trust or installment after delivery",
  property_17: "Railroad rolling stock",
  property_18:
    "Overhead Transmission and Distribution lines, except those on or within 1000 feet of Insured Premises",
  property_19: "Satellites",
  property_20:
    "Underground mines, wells, caverns including property contained therein",
  property_21: "Vehicles licensed for highway use",
  property_22: "Water",
  peril_1:
    "Contamination, shrinkage or change in color, flavor, texture or finish",
  peril_2: "Change in temperature, humidity",
  peril_3: "Delay or loss of market",
  peril_4:
    "Deterioration, depletion, rust, corrosion, erosion, wear and tear, inherent vice or latent defect",
  peril_5: "Dishonest act",
  peril_6: "Faulty workmanship, material, construction or design",
  peril_7: "Indirect or remote loss",
  peril_8: "Insect or vermin",
  peril_9: "Insurrection, rebellion etc.",
  peril_10: "Seizure by public authority",
  peril_11: "Manufacturing or processing operations",
  peril_12: "Mysterious disappearance or shortage of inventory",
  peril_13: "Nuclear reaction/radiation",
  peril_14:
    "Settling, cracking, shrinking, bulging or expansion of pavements, foundations walls, floors or ceilings",
  peril_15:
    "Voluntary parting with title or possession of property if induced by fraud, scheme, trick or false pretence",
};
