import { DeductibleInfo } from "../../../client";

export type Flag = { explanation: string; type: "Warning" | "Failure" };

export const warningsForDeductibleInfo = (info: DeductibleInfo): Flag[] => {
  return (info.flag_reasons ?? []).map((explanation) => ({
    explanation,
    type: "Warning",
  }));
};

export const failuresForDeductibleInfo = (info: DeductibleInfo): Flag[] => {
  return info.failure_explanation
    ? [
        {
          explanation: info.failure_explanation,
          type: "Failure",
        },
      ]
    : [];
};

export const warningAndFailureStringsForDeductibles = (
  info: DeductibleInfo
): string[] => {
  const warnings = warningsForDeductibleInfo(info);
  const failures = failuresForDeductibleInfo(info);
  const allIssues = [...warnings, ...failures];

  return allIssues.map((issue) => `${issue.type}: ${issue.explanation}`);
};
