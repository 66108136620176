import { Card } from "primereact/card";
import { Editor } from "@tinymce/tinymce-react";
import { useContext, useRef, useState } from "react";
import { SafeReportResponse } from "../Conversion";
import { readableLayer, sortedLayersFromReport } from "../layers/util";
import { formatAbbreviatedMoney } from "../Money";
import { groupBy } from "lodash";
import { ApiDeductibleForPeril, RawAddress } from "../../../client";
import { ThemeContext } from "../../../themes/Theme";
import { Toast } from "primereact/toast";

const generateLayerString = (specialtyPropertyInfo: SafeReportResponse) => {
  const companyName =
    specialtyPropertyInfo.report_json.company_info.company_name;
  const layerInfo = sortedLayersFromReport(specialtyPropertyInfo).reverse();
  if (layerInfo.length === 0) {
    return `We didn't find any layers that <b>${companyName}</b> is interested in.`;
  }

  const isSingleLayer = layerInfo.length === 1;

  let readableLayers = "";
  for (let i = 0; i < layerInfo.length; i++) {
    const layer = layerInfo[i];
    const layerRange = readableLayer(layer);
    readableLayers = readableLayers + ` ${layerRange}`;
    if (layer.target) {
      readableLayers =
        readableLayers +
        ` with a target premium of ${formatAbbreviatedMoney(layer.target)}`;
    }

    const punctuation = i === layerInfo.length - 1 ? "." : ",";
    readableLayers += punctuation;
  }

  return `The broker is suggesting the following layer${
    isSingleLayer ? "" : "s"
  }, ${readableLayers}  Given that <b>${companyName}</b> is within CNP's appetite, we are looking at 10% p/o for ${
    isSingleLayer ? "this layer" : "all of these layers"
  }.`;
};

const generateCatExposuresString = (
  specialtyPropertyInfo: SafeReportResponse,
  addresses: RawAddress[]
) => {
  let catExposureString = "";

  const filterAddressesForDeductibleInfo = (
    addresses: RawAddress[],
    deductibleInfo: ApiDeductibleForPeril | undefined | null,
    key: keyof RawAddress
  ) => {
    if (
      !deductibleInfo ||
      !deductibleInfo.filter_fallthrough ||
      deductibleInfo.filter_fallthrough.length === 0
    ) {
      return [];
    }

    const defaultDeductible =
      deductibleInfo.filter_fallthrough[
        deductibleInfo.filter_fallthrough?.length - 1
      ];

    // If the last fallthrough isn't an always true condition, just return everything with a non-1 deductible
    const filteredAddresses = addresses.filter(
      (address) => ((address[key] as number | undefined | null) ?? 1) < 1
    );
    if (
      defaultDeductible.condition.type !== "always_true" ||
      defaultDeductible.not_covered
    ) {
      return filteredAddresses;
    }

    // Otherwise, return everything that doesn't appear to match our final fallthrough condition. This
    // should catch everything that isn't in the "everything else" bucket
    return filteredAddresses.filter((address) => {
      const deductible = address[key] as number;

      const hasFlatDollar =
        defaultDeductible.deductible_flat_dollar_amount !== null;
      let hasMismatchOnFlatDollar = false;
      if (hasFlatDollar) {
        const exposure = Math.round(deductible * address.tiv);
        hasMismatchOnFlatDollar =
          exposure !== defaultDeductible.deductible_flat_dollar_amount;
      }

      const hasPercentage =
        defaultDeductible.deductible_percentage_0_to_100 !== null;
      let hasMismatchOnPercentage = false;
      if (hasPercentage) {
        hasMismatchOnPercentage =
          deductible !==
          (defaultDeductible.deductible_percentage_0_to_100 ?? 0) / 100.0;
      }

      if (hasFlatDollar || hasPercentage) {
        return hasMismatchOnFlatDollar || hasMismatchOnPercentage;
      }

      return true;
    });
  };

  const groupByStateAndAddToExposureString = (
    perilAddresses: RawAddress[],
    exposureType: string,
    totalTiv: number
  ) => {
    const filteredAddresses = perilAddresses.filter(
      (address) => (address.state ?? "") !== ""
    );
    const groupedByState = groupBy(filteredAddresses, "state");
    // Only include states where the tiv in that state is greater than
    // 1% of the total tiv of the policy
    const listOfStateAddresses = Array.from(
      Object.values(groupedByState)
    ).filter(
      (stateAddresses) =>
        stateAddresses.reduce((total, address) => total + address.tiv, 0) >
        totalTiv / 100
    );
    if (listOfStateAddresses.length === 0) {
      return;
    }

    listOfStateAddresses.sort(
      (a, b) =>
        b.reduce((total, address) => total + address.tiv, 0) -
        a.reduce((total, address) => total + address.tiv, 0)
    );

    catExposureString += `<br><div>There is <b>${exposureType}</b> exposure in the following states:</div>`;
    for (const catAddresses of listOfStateAddresses) {
      const totalTiv = catAddresses.reduce(
        (total, address) => total + address.tiv,
        0
      );
      catExposureString += `<div>${
        catAddresses[0].state
      }: ${formatAbbreviatedMoney(totalTiv)}</div>`;
    }
  };

  const addressesWithTiv = addresses.filter((address) => address.tiv > 0);
  const totalTiv = addressesWithTiv.reduce(
    (total, address) => total + address.tiv,
    0
  );
  const windstormExposureAddresses = filterAddressesForDeductibleInfo(
    addressesWithTiv,
    specialtyPropertyInfo.report_json.deductible_info
      ?.named_storm_specific_deductible,
    "named_storm_deductible"
  );
  const earthquakeExposureAddresses = filterAddressesForDeductibleInfo(
    addressesWithTiv,
    specialtyPropertyInfo.report_json.deductible_info
      ?.earthquake_specific_deductible,
    "eq_deductible"
  );
  const floodExposureAddresses = filterAddressesForDeductibleInfo(
    addressesWithTiv,
    specialtyPropertyInfo.report_json.deductible_info
      ?.flood_specific_deductible,
    "flood_deductible"
  );

  if (windstormExposureAddresses && windstormExposureAddresses.length > 0) {
    groupByStateAndAddToExposureString(
      windstormExposureAddresses,
      "windstorm",
      totalTiv
    );
  }
  if (earthquakeExposureAddresses && earthquakeExposureAddresses.length > 0) {
    groupByStateAndAddToExposureString(
      earthquakeExposureAddresses,
      "earthquake",
      totalTiv
    );
  }
  if (floodExposureAddresses && floodExposureAddresses.length > 0) {
    groupByStateAndAddToExposureString(
      floodExposureAddresses,
      "flood",
      totalTiv
    );
  }

  return catExposureString;
};

const generateLossRunSummary = (specialtyPropertyInfo: SafeReportResponse) => {
  const lossRunInfo = specialtyPropertyInfo.report_json.loss_run;
  const yearByYearLosses =
    lossRunInfo.computed_loss_runs_by_year.length > 0
      ? lossRunInfo.computed_loss_runs_by_year
      : lossRunInfo.parsed_loss_runs_by_year;
  yearByYearLosses.sort((a, b) => b.policy_year.localeCompare(a.policy_year));

  if (yearByYearLosses.length === 0) {
    return "No loss run info found";
  }

  let lossRunString = "<div>";

  const lastFiveYearsLosses = yearByYearLosses.slice(0, 5);
  const lastFiveYearsTotalLoss = lastFiveYearsLosses.reduce(
    (total, year) => total + (year.net_incurred?.value ?? 0),
    0
  );
  const numYearsWithLosses = lastFiveYearsLosses.filter(
    (year) => (year.net_incurred?.value ?? 0) !== 0
  ).length;
  // Let's avoid assuming there are exactly 5 years
  lossRunString += `Over the last ${
    lastFiveYearsLosses.length
  } years there were ${formatAbbreviatedMoney(
    lastFiveYearsTotalLoss
  )} in losses occuring in ${numYearsWithLosses} of the ${
    lastFiveYearsLosses.length
  } years for an average loss of ${formatAbbreviatedMoney(
    lastFiveYearsTotalLoss / lastFiveYearsLosses.length
  )} / 5 per year.`;

  if (yearByYearLosses.length > 5) {
    const totalLoss = yearByYearLosses.reduce(
      (total, year) => total + (year.net_incurred?.value ?? 0),
      0
    );
    lossRunString += `  The broker also provided loss history going back ${
      yearByYearLosses.length
    } years with a total loss of ${formatAbbreviatedMoney(
      totalLoss
    )} and an average loss of ${formatAbbreviatedMoney(
      totalLoss / yearByYearLosses.length
    )}.`;
  }

  lossRunString += `</div><br>`;

  const rows = lastFiveYearsLosses
    .map(
      (row) => `
    <tr>
      <td>${row.policy_year}</td>
      <td>${row.open_claims + row.closed_claims}</td>
      <td>${row.open_claims}</td>
      <td>${
        row.ground_up_loss
          ? formatAbbreviatedMoney(row.ground_up_loss.value)
          : "N/A"
      }</td>
      <td>${formatAbbreviatedMoney(row.net_incurred?.value ?? 0)}</td>
    </tr>
  `
    )
    .join("");

  lossRunString += `
    <table>
      <thead>
        <tr>
          <th>Year</th>
          <th>Total Claims</th>
          <th>Open Claims</th>
          <th>Ground Up Loss</th>
          <th>Net Incurred</th>
        </tr>
      </thead>
      <tbody>
        ${rows}
      </tbody>
    </table>
  `;

  return lossRunString;
};

const generateLayerLossCosts = (specialtyPropertyInfo: SafeReportResponse) => {
  const layerInfo = sortedLayersFromReport(specialtyPropertyInfo).reverse();

  let layerString = "";
  for (const layer of layerInfo) {
    layerString += readableLayer(layer);
    const estLossCost = layer.range / 8;
    const yrImpact = layer.range / 2.5;
    layerString += `<ul><li>Est loss cost: ${formatAbbreviatedMoney(
      estLossCost
    )}</li><li>1/100 Yr Impact: ${formatAbbreviatedMoney(yrImpact)}</li></ul>`;
    layerString += "<br>";
  }
  return layerString;
};

const generateInitialReferralValue = (
  specialtyPropertyInfo: SafeReportResponse
) => {
  const report = specialtyPropertyInfo.report_json;
  const companyName = report.company_info.company_name;
  const brokerage = report.company_info.broker_name;

  const addresses = specialtyPropertyInfo.report_json.sovs
    .filter((sov) => sov.is_enabled)
    .flatMap((sov) => sov.addresses);
  const totalTIV = addresses.reduce((total, address) => total + address.tiv, 0);
  const addressesWithSquareFootage = addresses.filter(
    (address) => address.square_footage && address.square_footage !== ""
  );
  const squareFootageTiv = addressesWithSquareFootage.reduce(
    (total, address) => total + address.tiv,
    0
  );
  const totalSquareFootage = addressesWithSquareFootage.reduce(
    (total, address) =>
      total + Number.parseFloat(address.square_footage ?? "0"),
    0
  );
  const costPerFoot = squareFootageTiv / totalSquareFootage;

  const groupedAddressesByOccupancy = groupBy(
    addresses,
    "occupancy_description"
  );

  const groupedAddresses = Array.from(
    Object.values(groupedAddressesByOccupancy)
  ).filter(
    (addresses) => addresses.length > 0 && addresses[0].occupancy_description
  );
  groupedAddresses.sort(
    (a, b) =>
      b.reduce((total, address) => total + address.tiv, 0) -
      a.reduce((total, address) => total + address.tiv, 0)
  );

  let mostCommonOccupancy = undefined;
  let secondMostCommonOccupancy = undefined;
  if (groupedAddresses.length > 0) {
    const addressesForOccupancy = groupedAddresses[0];
    const description = addressesForOccupancy[0].occupancy_description ?? "";
    const tiv = addressesForOccupancy.reduce(
      (total, address) => total + address.tiv,
      0
    );
    mostCommonOccupancy = { tiv: tiv, description: description };
  }
  if (groupedAddresses.length > 1) {
    const addressesForOccupancy = groupedAddresses[1];
    const description = addressesForOccupancy[0].occupancy_description ?? "";
    const tiv = addressesForOccupancy.reduce(
      (total, address) => total + address.tiv,
      0
    );
    secondMostCommonOccupancy = { tiv: tiv, description: description };
  }

  const squareFootageString =
    totalSquareFootage > 0
      ? `The TIV of buildings with square footage provided is ${formatAbbreviatedMoney(
          squareFootageTiv
        )} on ${formatAbbreviatedMoney(
          totalSquareFootage,
          false
        )} square footage for a cost of ${formatAbbreviatedMoney(
          costPerFoot
        )} per square foot.`
      : "";

  return `
  <div>
  <div>
  <b>${companyName}</b> ${
    report.company_info.effective_date
  } is renewal business that has been written by CNP for the prior five years.  <b>${companyName}</b> was handled by ${brokerage} in all five of the prior years.
  </div>
  <br>
  <div>
  ${generateLayerString(specialtyPropertyInfo)}
  </div>
  <br>
  <div>
  ${formatAbbreviatedMoney(
    totalTIV
  )} TIV up 30% YOY.  Increase due to both new properties (20%) as well as value adjustments (10%).  ${squareFootageString}
  </div>
  <br>
  <div>
  ${
    mostCommonOccupancy &&
    `Majority occupancy is ${
      mostCommonOccupancy.description
    } (${formatAbbreviatedMoney(mostCommonOccupancy.tiv)}) ${
      secondMostCommonOccupancy
        ? `as well as ${
            secondMostCommonOccupancy.description
          } (${formatAbbreviatedMoney(secondMostCommonOccupancy.tiv)}). `
        : ". "
    }`
  } ${report.company_info.company_description}
  </div>
  ${generateCatExposuresString(specialtyPropertyInfo, addresses)}
  <br>
  <div>
  ${generateLossRunSummary(specialtyPropertyInfo)}
  </div>
  <br>
  <div>
  ${generateLayerLossCosts(specialtyPropertyInfo)}
  <b>WS</b>
  <br>
  1/100 Yr Impact: $121M
  <br>
  HCHAT:
  <ul>
  <li>$95M to $140M in Miami</li>
  <li>$80M to $110M in Houston</li>
  <li>$50M to $120M in Boston</li>
  </ul>
  <br>
  <b>EQ</b>
  <br>
  CA EQ 1/100 Impact: $200M
  <br>
  HCHAT:
  <ul>
  <li>$200M in California</li>
  </ul>
  <br>
  </div>
  <br>
  <div>
  <table>
    <thead>
      <tr>
        <th>Street</th>
        <th>City</th>
        <th>State</th>
        <th>Country</th>
        <th>NML</th>
        <th>PML</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>4 Industrial Boulevard</td>
        <td>Cold Spring-Sauk Rapids</td>
        <td>MN</td>
        <td>US</td>
        <td>$1.2MM</td>
        <td>$27MM</td>
      </tr>
      <tr>
        <td>502 W Main Street</td>
        <td>Arcadia</td>
        <td>WI</td>
        <td>US</td>
        <td>$900K</td>
        <td>$20MM</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
`;
};

type ReferralCardProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

export const ReferralCard: React.FC<ReferralCardProps> = ({
  specialtyPropertyInfo,
}) => {
  const [text, setText] = useState(
    generateInitialReferralValue(specialtyPropertyInfo)
  );

  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const toast = useRef<Toast>(null);

  return (
    <>
      <Toast ref={toast} />
      <Card title="Underwriting">
        <Editor
          value={text}
          init={{
            ...{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "table",
                "paste",
                "help",
                "wordcount",
              ],
              toolbar:
                "bold italic underline | table | numlist bullist | customcopy",
              setup: (editor) => {
                editor.ui.registry.addButton("customcopy", {
                  icon: "copy",
                  tooltip: "Copy content",
                  onAction: async () => {
                    const content = editor.getContent({ format: "html" });
                    const textContent = editor.getContent({ format: "text" });
                    const htmlBlob = new Blob([content], { type: "text/html" });
                    const plainBlob = new Blob([textContent], {
                      type: "text/plain",
                    });

                    try {
                      await navigator.clipboard.write([
                        new ClipboardItem({
                          "text/html": htmlBlob,
                          "text/plain": plainBlob,
                        }),
                      ]);
                    } catch (err) {
                      // Fallback to text-only if clipboard.write not supported
                      navigator.clipboard.writeText(content);
                    }

                    toast.current?.show({
                      severity: "success",
                      summary: "Copied",
                      detail: "Message copied to clipboard",
                      life: 5000,
                    });
                  },
                });
              },
              content_style:
                'body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif; font-size: 14px }',
            },
            ...(isLightMode ? {} : { skin: "oxide-dark", content_css: "dark" }),
          }}
          onEditorChange={(content) => {
            setText(content);
          }}
        />
      </Card>
    </>
  );
};
