import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { STATE_ABBREV_TO_STATE_NAME } from "../../../datasets";
import { InputTextarea } from "primereact/inputtextarea";
import * as client from "../../../client";
import { ColumnEditorOptions } from "primereact/column";

export type Editor = React.ComponentType<{
  options: ColumnEditorOptions;
  address: client.RawAddress;
  disabled: boolean;
  additionalStyles: React.CSSProperties;
}>;

export const OneLineStringEditor: Editor = ({
  options: { value, editorCallback },
  disabled,
  additionalStyles,
}) => {
  return (
    <InputText
      style={{
        width: "calc(100% - 0.5rem)",
        ...additionalStyles,
      }}
      value={value ?? ""}
      onChange={(e) => editorCallback?.(e.target.value)}
      disabled={disabled}
    />
  );
};

export const MultiLineStringEditor: Editor = ({
  options: { value, editorCallback },
  disabled,
  additionalStyles,
}) => {
  return (
    <InputTextarea
      style={{ width: "calc(100% - 0.5rem)", ...additionalStyles }}
      value={value ?? ""}
      onChange={(e) => editorCallback?.(e.target.value)}
      disabled={disabled}
    />
  );
};

export const MoneyEditor: Editor = ({
  options: { value, editorCallback },
  disabled,
  additionalStyles,
}) => {
  return (
    <InputNumber
      style={{ width: "calc(100% - 0.5rem)", ...additionalStyles }}
      value={value ?? 0}
      onValueChange={(e) => editorCallback?.(e.value)}
      mode="currency"
      currency="USD"
      locale="en-US"
      disabled={disabled}
    />
  );
};

export const EnumEditor = ({
  options: { value, editorCallback },
  enumValues,
  disabled,
  additionalStyles,
}: {
  options: ColumnEditorOptions;
  enumValues: string[];
  disabled: boolean;
  additionalStyles: React.CSSProperties;
}) => {
  return (
    <Dropdown
      style={{ width: "calc(100% - 0.5rem)", ...additionalStyles }}
      options={enumValues}
      value={value}
      onChange={(e) => editorCallback?.(e.value)}
      disabled={disabled}
    />
  );
};

export const StateEditor: Editor = ({
  options,
  address,
  disabled,
  additionalStyles,
}) => {
  if (address.country_code == "US") {
    return (
      <EnumEditor
        options={options}
        enumValues={Object.keys(STATE_ABBREV_TO_STATE_NAME)}
        disabled={disabled}
        additionalStyles={additionalStyles}
      />
    );
  } else {
    return (
      <OneLineStringEditor
        options={options}
        address={address}
        disabled={disabled}
        additionalStyles={additionalStyles}
      />
    );
  }
};
